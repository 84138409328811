import styled, {css} from "styled-components";

const stateStyles = (state = '') =>
    ({
        show: css`
          transform: scale(1);
        `,
    }[state]);

const bgStateStyles = (state = '') =>
    ({
        show: css`
          transform: scale(1);
          opacity: 1;
        `,
    }[state]);

export const ModalStyled = styled.div<{ state: string }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.9);
  opacity: 0;
  transform: scale(0);
  transition: all .1s ease-in-out;
  overflow: hidden;
  overflow-y: auto;
  
  ${({ state }) => bgStateStyles(state)}
`;

export const ModalWrapperInner = styled.div<{ state: string }>`
  position: relative;
  top: 15%;
  display: block;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 30px;
  text-align: center;
  transform: scale(0);
  transition: all .2s ease-in-out;

  ${({ state }) => stateStyles(state)}
`;

export const ButtonClose = styled.img`
  cursor: pointer;
  margin-bottom: 18px;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    max-width: 52px;
    margin-bottom: 13px;
  }
`;

export const ModalTitle = styled.h3`
  margin-top: 0;
  margin-bottom: 38px;
  font-size: 32px;
  line-height: 1.125;
  color: ${props => props.theme.colors.white};
  letter-spacing: -1.76px;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    margin-bottom: 27px;
    font-size: 23px;
  }
`;

export const ModalContent = styled.div`
  
`;
