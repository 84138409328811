import React from 'react';
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";

import {selectAccounts} from "../../store/web3/web3ProviderSlice";
import {selectUser} from "../../store/auth/authSlice";

import ButtonHeart from "../button-heart/ButtonHeart";

import {
    Address,
    WalletWrapper,
    WalletAddressStyled,
    GuestText,
    UserStats,
    NFTCount,
    ImageWrapper,
    Image,
    Count,
    Text,
    SerumCount,
    Counters,
} from "./WalletAddress.style";

import iconWalletNFT from '../../resources/img/icon-wallet-nft.png';
import iconWalletSerum from '../../resources/img/icon-wallet-serum.png';

const variantsWalletAddress = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.4,
            delay: 0.2
        }
    },
}

const variantsWalletAddressSpan = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.4,
            delay: 0.4
        }
    },
}

const variantsWalletImg = {
    hidden: {
        opacity: 0,
        scale: 0,
    },
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            duration: 0.4,
            delay: 0.2
        }
    },
}

const variantsUserStats = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.4,
            delay: 0.2
        }
    },
}

const WalletAddress = () => {
    const accounts = useSelector(selectAccounts);
    const user = useSelector(selectUser);
    const nftCount = user?.ainfts.length;
    const serumCount = getAvailableSerumCount();

    function getAvailableSerumCount() {
        let total = 0;
        if(user && user.serums.length > 0) {
            user.serums.forEach((serum) => {
                total += serum.total - serum.used;
            })
        }
        return total;
    }

    return (
        <>
            <WalletWrapper>
                <WalletAddressStyled
                    initial="hidden"
                    animate="visible"
                    variants={variantsWalletAddress}
                >Hello, <Address
                    initial="hidden"
                    animate="visible"
                    variants={variantsWalletAddressSpan}
                >
                    {accounts[0] ? accounts[0] : (
                        <>
                            <GuestText><span>0x0000000000000000000000000000000000000000</span></GuestText>
                        </>
                    )}
                </Address></WalletAddressStyled>

                <UserStats
                    initial="hidden"
                    animate="visible"
                    variants={variantsUserStats}
                >
                    <Counters>
                        <NFTCount to="/">
                            <ImageWrapper className="image-wrapper">
                                <Image src={iconWalletNFT} faded={nftCount == 0} />
                                <Count className="count"><span>{nftCount}</span></Count>
                            </ImageWrapper>
                            <Text>AI NFT</Text>
                        </NFTCount>
                        <SerumCount to="/">
                            <ImageWrapper className="image-wrapper">
                                <Image src={iconWalletSerum} faded={serumCount == 0} />
                                <Count className="count"><span>{serumCount}</span></Count>
                            </ImageWrapper>
                            <Text>AI SERUM</Text>
                        </SerumCount>
                    </Counters>
                    <ButtonHeart count={user?.balance || 0} />
                </UserStats>
            </WalletWrapper>
        </>
    );
};

export default WalletAddress;
