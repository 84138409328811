import styled, {css} from "styled-components";
import {Text} from "../../styles/general.style";

export const StepsSerumListWrapper = styled.div`
  max-width: 1190px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    display: block;
    //max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const ListTitle = styled.h2`
  margin-top: 0;
  margin-bottom: 32px;
  font-size: 43px;
  line-height: 1.093;
  color: ${props => props.theme.colors.white};
  letter-spacing: -0.74px;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    margin-bottom: 22px;
    font-size: 30px;
  }
`;

export const StepsSerumListStyled = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    display: block;
  }
`;

export const StepSerumItem = styled.div`
  position: relative;
  padding-top: 307px;
  
  &:nth-child(1) {
    img {
      width: 133.533%;
      top: 2%;
      left: -19%;
    }
  }

  &:nth-child(2) {
    img {
      width: 180.57%;
      top: 24%;
      left: -42%;
    }
  }

  &:nth-child(3) {
    img {
      width: 101.204%;
      top: 2%;
      left: -19%;
    }
  }

  &:nth-child(4) {
    img {
      width: 150.93%;
      top: 4%;
      left: -15%;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    margin-bottom: 30px;
    padding-top: 0;
    text-align: center;
    
    &:last-child {
      margin-bottom: 0;
    }
    
    img {
      width: auto!important;
      max-width: 60%;
      position: relative;
      top: auto!important;
      left: auto!important;
    }
  }
`;

export const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
`;

export const Title = styled.h3`
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 35px;
  line-height: 1.343;
  letter-spacing: -0.61px;
  color: ${props => props.theme.colors.white};
  z-index: 1;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    font-size: 24.5px;
  }
`;

export const Subtitle = styled(Text)`
  margin-top: 8px;
  margin-bottom: 0;
`;
