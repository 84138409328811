import React, {ReactNode} from 'react';

import { ButtonStyled } from './Button.style';

type ButtonProps = {
    children: ReactNode,
    link?: string,
    onClick?: () => void,
    variant?: "outline" | "light" | "dark" | "gradientRainbow" | "gradientSunset",
    type?: "v2" | "v3" | "v4",
    icon?: boolean,
};

const Button = (
    {
        children,
        link,
        onClick,
        variant,
        type,
        icon,
    }: ButtonProps
) => {

    const handleOnClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        if(onClick) {
            event.preventDefault();
            onClick();
        }
    }

    return (
        <ButtonStyled
            to={link ? link : ""}
            onClick={handleOnClick}
            variant={variant ? variant : ''}
            type={type ? type : ''}
            icon={icon ? icon : false}
        >
            {children}
        </ButtonStyled>
    );
};

export default Button;
