import React from 'react';

import Button from "../../../../components/button/Button";

import {
    ButtonClose,
    ModalContent,
    ModalStyled,
    ModalTitle,
    ModalWrapperInner
} from '../../../../styles/modal.style';

import {
    ConfirmActionWrapper, ModalConfirmStyled
} from './ModalConfirm.style';

import iconClose from '../../../../resources/img/icon-close.png';

type ModalGenerateProps = {
    isOpen: boolean,
    message: string,
    closeModal: () => void,
    handleConfirm: () => void
};

const ModalConfirm = ({isOpen, message, closeModal, handleConfirm}: ModalGenerateProps) => {
    return (
        <ModalConfirmStyled>
            <ModalStyled state={isOpen ? "show" : ""}>
                <ModalWrapperInner state={isOpen ? "show" : ""}>
                    <ButtonClose
                        src={iconClose}
                        onClick={closeModal}
                    />
                    <ModalTitle>Confirm action</ModalTitle>
                    <ModalContent>
                        <ConfirmActionWrapper>
                            <h3>{message}</h3>
                            <Button
                                onClick={() => handleConfirm()}
                                variant="gradientSunset"
                                type="v2"
                            >Confirm</Button>
                        </ConfirmActionWrapper>
                    </ModalContent>
                </ModalWrapperInner>
            </ModalStyled>
        </ModalConfirmStyled>
    );
};

export default ModalConfirm;
