import React from 'react';

import ButtonHeart from "../button-heart/ButtonHeart";

import {
    AiSerumCardStyled,
    CardBg,
    Count,
    ID,
    SerumInfo,
    Title
} from './AISerumCard.style';

import bgAISerum2 from '../../resources/img/bg-ai-serum-2.png';

const AiSerumCard = () => {
    return (
        <AiSerumCardStyled>
            <SerumInfo>
                <CardBg src={bgAISerum2} />
                <Title>AI SERUM</Title>
                <ID>#1</ID>
                <Count>1</Count>
            </SerumInfo>
            <ButtonHeart
                variant="v2"
                textUpper={true}
                count={0}
            />
        </AiSerumCardStyled>
    );
};

export default AiSerumCard;
