import styled, {css} from "styled-components";
import {Link} from "react-router-dom";

const variantStyles = (variant = '') =>
    ({
        v2: css`
          ${ButtonHeartInner} {
            background-color: ${props => props.theme.colors.white};
          }
          
          ${TextUpper} {
            top: auto;
            bottom: -22px;
          }
          
          ${Price} {
            color: ${props => props.theme.colors.black};
          }
          
          ${Token} {
            color: rgba(0, 0, 0, .34);
          }
        `,
    }[variant]);

export const ButtonHeartStyled = styled(Link)<{variant: string}>`
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: none;

  ${({ variant }) => variantStyles(variant)}
`;

export const TextUpper = styled.p`
  margin: 0;
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 10px;
  color: rgba(255, 255, 255, .19);
`;

export const IconButton = styled.img`
  position: relative;
  margin-right: -10px;
  z-index: 1;
`;

export const ButtonHeartInner = styled.div`
  width: 191px;
  height: 52px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  background-color: rgba(255, 255, 255, .15);
  border-radius: 16px;
  box-sizing: border-box;
`;

export const IconHeart = styled.img`
  display: block;
`;

export const Price = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 13.5px;
  font-size: 14px;
  letter-spacing: -0.24px;
  color: ${props => props.theme.colors.white};
`;

export const Token = styled.p`
  margin-top: 2px;
  margin-bottom: 0;
  margin-left: 22px;
  font-size: 13px;
  letter-spacing: -0.22px;
  color: rgba(255, 255, 255, .34);
`;
