import styled from "styled-components";

import { AssetNumber } from "../../components/nft-card/NFTCard.style";
import {TitleHeading} from "../../styles/general.style";
import {PlayBtn, PlayBtnWrapper, VideoPreviewStyled} from "../../components/video-preview/VideoPreview.style";

export const MyVideosStyled = styled.div`
  ${TitleHeading} {
    margin-top: 41.5px;
    margin-bottom: 24px;
    color: ${props => props.theme.colors.white};
  }
`;

export const VideosList = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;

  @media (max-width: 651px) {
    justify-content: center;
  }
`;

export const MyVideo = styled.div`
  position: relative;
  width: 303px;
  margin-bottom: 20px;
  padding: 20px 17.7px 25px 18px;
  border-radius: 27px;
  border: 1px solid rgba(255, 255, 255, .34);
  box-sizing: border-box;
  
  ${AssetNumber} {
    display: inline-block;
    position: relative;
    bottom: 0;
    left: 0;
    margin-top: 12px;
  }

  @media (max-width: 651px) {
    min-width: 80%;
  }
`;

export const TextSpoken = styled.p`
  margin-top: 12px;
  margin-bottom: 0;
  font-size: 20px;
  letter-spacing: -1.1px;
  color: ${props => props.theme.colors.white};
`;

export const FeaturedVideo = styled.div`
  display: flex;
  align-items: center;
  
  ${VideoPreviewStyled} {
    border-radius: 28px;
    overflow: hidden;
  }
  
  ${PlayBtnWrapper} {
    height: 32.95%;
    width: 32.95%;
    
    ${PlayBtn} {
      width: 22.74%;
    }
  }
`;

export const ShareBtnsList = styled.div`
  margin-left: 11px;
`;

export const ShareBtnIcon = styled.div`
  display: block;
  height: 54px;
  width: 54px;
  margin-bottom: 4px;
  border-radius: 50%;
  overflow: hidden;
  
  &:last-child {
    margin-bottom: 0;
  }
  
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    margin-right: 0;
  }
`;
