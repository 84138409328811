import React from 'react';

import {
    ListTitle,
    StepSerumItem,
    StepsSerumListStyled,
    StepsSerumListWrapper,
    Image,
    Title,
    Subtitle
} from './StepsSerumList.style';
import { Container } from '../../styles/general.style';

import stepImage1 from '../../resources/img/steps/step-serum-1.png';
import stepImage2 from '../../resources/img/steps/step-serum-2.png';
import stepImage3 from '../../resources/img/steps/step-serum-3.png';
import stepImage4 from '../../resources/img/steps/step-serum-4.png';

const StepsSerumList = () => {
    return (
        <>
            <Container>
                <ListTitle>How to get an AI Serum</ListTitle>
            </Container>
            <StepsSerumListWrapper className="steps-serum-list-wrapper">
                <StepsSerumListStyled>
                    <StepSerumItem>
                        <Image src={stepImage1} />
                        <Title>Own your voice</Title>
                        <Subtitle>Own a unique voice AI NFT</Subtitle>
                    </StepSerumItem>
                    <StepSerumItem>
                        <Image src={stepImage2} />
                        <Title>Trade it</Title>
                        <Subtitle>You know this part</Subtitle>
                    </StepSerumItem>
                    <StepSerumItem>
                        <Image src={stepImage3} />
                        <Title>Generate AI Serums</Title>
                        <Subtitle>A consumable that makes your NFT cool</Subtitle>
                    </StepSerumItem>
                    <StepSerumItem>
                        <Image src={stepImage4} />
                        <Title>Use it</Title>
                        <Subtitle>Give life to your NFT,<br/>
                            speak in the Metaverse</Subtitle>
                    </StepSerumItem>
                </StepsSerumListStyled>
            </StepsSerumListWrapper>
        </>
    );
};

export default StepsSerumList;
