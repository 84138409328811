import styled from "styled-components";
import { motion } from "framer-motion";

import {Text} from "../../styles/general.style";

import beforeCard from '../../resources/img/steps/step-3.png';
import afterCard from '../../resources/img/after-card.png';
import beforeTitle from '../../resources/img/bg-title.svg';

export const HomePage = styled.div`
  nav {
    margin-bottom: 160px;
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    nav {
      margin-bottom: 92px;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    nav {
      margin-top: 30px;
      margin-bottom: 120px;
    }
  }
`;

export const Banner = styled(motion.div)`
  width: 100%;
  margin-top: 69px;
  display: flex;
  justify-content: space-between;
  background-color: ${props => props.theme.colors.banner};
  border-radius: 45px;
  overflow: hidden;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    margin-top: 60px;
    flex-direction: column;
    align-items: center;
  }
`;

export const BannerContent = styled.div`
  padding: 65px 51px 45px 51px;
`;

export const BannerTitle = styled.h2`
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 32px;
  line-height: 1.125;
  font-weight: 400;
  color: ${props => props.theme.colors.white};
  letter-spacing: -1.76px;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 23px;
  }
`;

export const BannerImage = styled.img`
  max-width: 100%;
  margin-right: 157px;
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    margin-right: 0;
  }
`;

export const StepsSerumSection = styled.div`
  margin-top: 182px;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    margin-top: 129px;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    margin-top: 60px;
  }
`;
