import React from 'react';

import { Line, Progress } from './ProgressBar.style';

type ProgressBarProps = {
    percent: number
}

const ProgressBar = ({percent}: ProgressBarProps) => {
    return (
        <Line>
            <Progress width={percent} />
        </Line>
    );
};

export default ProgressBar;
