import styled from "styled-components";
import {motion} from "framer-motion";

import {Text} from "../../styles/general.style";

import beforeTitle from "../../resources/img/bg-title.svg";
import beforeCard from "../../resources/img/steps/step-3.png";
import afterCard from "../../resources/img/after-card.png";

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Description = styled.div`
  z-index: 2;
  width: 100%;
  max-width: 704px;
  
  .steps-list-wrapper {
    margin-top: 54px;
  }
`;

export const Title = styled(motion.h1)`
  display: inline-block;
  position: relative;
  margin-top: 30px;
  margin-bottom: 0;
  font-size: 54px;
  line-height: 1.056;
  font-weight: 400;
  color: ${props => props.theme.colors.white};
  letter-spacing: -0.93px;

  &:after {
    content: "";
    display: block;
    width: 81px;
    height: 4px;
    background-image: ${props => props.theme.gradients.gradientSunset};
    position: absolute;
    right: -9px;
    bottom: -4px;
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    font-size: 43px;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    margin-top: 0;
    font-size: 35px;
  }
`;

export const BubbleText = styled(motion.div)`
    display: block;
    width: 191px;
    height: 150px;
    position: absolute;
    top: -123px;
    right: -172px;
    background-image: url(${beforeTitle});
    background-size: 100%;
    text-align: right;
  
    h1 {
      display: inline-block;
      margin-top: 34px;
      margin-right: 21px;
      margin-bottom: 0;
      font-size: 61px;
      line-height: 1.016;
      font-weight: 400;
      letter-spacing: -1.05px;
      background-image: ${props => props.theme.gradients.gradientSunset};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    @media (max-width: ${props => props.theme.breakpoints.md}) {
      width: 133.7px;
      height: 105px;
      top: -89px;
      right: -92px;
      
      h1 {
        margin-top: 21px;
        margin-right: 15px;
        font-size: 44px;
      }
    }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    top: -99px;
    right: 0;
  }
`;

export const Subtitle = styled(motion(Text))`
  margin-top: 53px;
  margin-bottom: 3px;
  font-size: 18px;
  letter-spacing: -0.03px;
  line-height: 2.167;
  color: ${props => props.theme.colors.white};

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    margin-top: 37px;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    line-height: 1.4;
  }
`;

export const Paragraph = styled(motion(Text))`
  max-width: 355px;
  margin-top: 0;
  margin-bottom: 38px;
  font-size: 14px;
  line-height: 1.143;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    max-width: 100%;
    margin-bottom: 27px;
  }
`;

export const CardHeader = styled(motion.div)`
  position: relative;
  z-index: 1;
  
  &:before {
    content: "";
    display: block;
    height: 129px;
    width: 129px;
    background-image: url(${beforeCard});
    background-size: 100%;
    position: absolute;
    left: 87%;
    top: 39%;
    z-index: 1;
  }

  &:after {
    content: "";
    display: block;
    height: 554px;
    width: 496px;
    background-image: url(${afterCard});
    background-size: 100%;
    position: absolute;
    left: 123px;
    top: -245px;
    z-index: 1;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    display: inline-block;
    margin-top: 90px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    
    &:before {
      height: 90px;
      width: 90px;
      left: 83%;
    }
    
    &:after {
      height: 387.79px;
      width: 347.2px;
      left: 107px;
      top: -173px;
    }
    
    & img {
      max-width: 90%;
    }
  }
`;
