import React, {useEffect, useState} from 'react';

import {SerumType} from "../../types/voices";

import Button from "../button/Button";

import {
    AISerumBg,
    AiSerumStatsStyled,
    AISerumTitle,
    BuyMoreBtn,
    BuySerumWrapper,
    ControlBtn,
    Controls,
    HeartCount,
    IconCount,
    SerumBtn,
    SerumIcon,
    SerumId
} from './AISerumStats.style';
import { GetVoiceBtn } from '../../layout/header/Header.style';

import BgAISerum from "../../resources/img/bg-ai-serum.png";
import IconSerum from "../../resources/img/steps/step-3.png";
import IconPlay from "../../resources/img/icon-play.png";
import IconPause from "../../resources/img/icon-pause.png";

import audioSample from "../../resources/file_example_MP3_1MG.mp3";

type AiSerumStatsProps = {
    serum: SerumType | null,
    handleUse?: (serum: SerumType | null) => void
};

const AiSerumStats = ({handleUse, serum}: AiSerumStatsProps) => {
    const serumCount = serum?.total || 0;
    const serumUsed = serum?.used || 0;
    const voiceID = serum?.voice_id;
    const serumAvailable = serumCount - serumUsed;
    const [audio] = useState(new Audio(audioSample));
    const [playing, setPlaying] = useState(false);
    const [showControls, setShowControls] = useState(false);

    useEffect(() => {
            playing ? audio.play() : audio.pause();
        },
        [playing]
    );

    useEffect(() => {
        audio.addEventListener('ended', () => setPlaying(false));
        return () => {
            audio.removeEventListener('ended', () => setPlaying(false));
            audio.pause();
        };
    }, []);

    const toggle = () => setPlaying(!playing);

    const handleShowControls = (show: boolean) => {
        if(!playing) {
            setShowControls(show)
        }
    }

    return (
        <AiSerumStatsStyled onMouseEnter={() => handleShowControls(true)} onMouseLeave={() => handleShowControls(false)}>
            <AISerumBg src={BgAISerum} active={Boolean(serumAvailable)} />
            <SerumIcon src={IconSerum} active={Boolean(serumAvailable)} />
            <AISerumTitle>AI SERUM</AISerumTitle>
            {serumAvailable < 1 ? (
                <BuySerumWrapper>
                    <p>You don’t have any AI Serum</p>
                    <GetVoiceBtn href="https://talkens.ai/" target="_blank">
                        <Button
                            link="/"
                            variant="gradientSunset"
                            type="v2"
                        >
                            Get one
                        </Button>
                    </GetVoiceBtn>
                </BuySerumWrapper>
            ) : (
                <>
                    <SerumId>VOICE <span>#{voiceID}</span></SerumId>
                    {handleUse && <SerumBtn onClick={() => handleUse(serum)}>USE IT</SerumBtn>}
                    <BuyMoreBtn to="/" />
                    <HeartCount><span>300</span> $HEART</HeartCount>
                    {showControls && <Controls onClick={toggle}>
						<ControlBtn src={playing ? IconPause : IconPlay} />
					</Controls>}
                </>
            )}
            <IconCount>{serumAvailable}</IconCount>
        </AiSerumStatsStyled>
    );
};

export default AiSerumStats;
