import React from 'react';

import NFTCard from "../../../../components/nft-card/NFTCard";

import {ApeType} from "../../../../types/apes";

import {ButtonClose, ModalContent, ModalStyled, ModalTitle, ModalWrapperInner } from '../../../../styles/modal.style';

import {
    ModalNFTGeneratedStyled, NFTGeneratedWrapper,
} from './ModalNFTGenerated.style';

import iconClose from '../../../../resources/img/icon-close.png';
import ButtonHeart from "../../../../components/button-heart/ButtonHeart";

type ModalNFTGeneratedProps = {
    ape: ApeType,
    isOpen: boolean,
    closeModal: () => void,
};

const ModalNFTGenerated = ({ape, isOpen, closeModal}: ModalNFTGeneratedProps) => {
    return (
        <ModalNFTGeneratedStyled>
            <ModalStyled state={isOpen ? "show" : ""}>
                <ModalWrapperInner state={isOpen ? "show" : ""}>
                    <ButtonClose
                        src={iconClose}
                        onClick={closeModal}
                    />
                    <ModalTitle>AI NFT Generated</ModalTitle>
                    <ModalContent>
                        <NFTGeneratedWrapper>
                            <ButtonHeart
                                variant="v2"
                                count={0}
                            />
                            <NFTCard
                                hasButton={true}
                                buttonText="Make it talk"
                                buttonLink={`/generate/${ape.id}`}
                                image={ape.image}
                                cardNumber={`#${ape.id}`}
                            />
                        </NFTGeneratedWrapper>
                    </ModalContent>
                </ModalWrapperInner>
            </ModalStyled>
        </ModalNFTGeneratedStyled>
    );
};

export default ModalNFTGenerated;
