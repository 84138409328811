import styled from "styled-components";
import {motion} from "framer-motion";
import {Link} from "react-router-dom";

export const Nav = styled(motion.nav)`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 64px;
  z-index: 2;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    justify-content: center;
    margin-top: 40px;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    margin-top: 30px;
  }
`;

export const LogoHeader = styled.img`
  display: block;
  max-width: 100%;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    margin-bottom: 20px;
  }
`;

export const GetVoiceBtn = styled.a`
  display: block;
  
  a {
    pointer-events: none;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  a {
    &:last-child {
      margin-left: 10px;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: 100%;
    justify-content: center;
  }

  @media (max-width: 450px) {

    a {
      &:last-child {
        margin-top: 10px;
        margin-left: 0;
      }
    }
  }
`;

export const MyVideosLink = styled(Link)`
  margin-right: 6px;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.6px;
  color: ${props => props.theme.colors.white};
  text-decoration: none;
  font-family: 'Avenir LT Std';
  font-weight: 500;
`;
