export const theme = {
    colors: {
        white: '#ffffff',
        black: '#000000',
        gray: '#969696',
        blue: '#1534EF',
        banner: '#93B7DC',
        pink: '#FB1399'
    },
    gradients: {
        gradientRainbow: 'linear-gradient(270deg, #2C94EF 0%, #7BCFF3 34%, #C49A82 60%, #DE7B57 74%, #6D2FBC 100%)',
        gradientSunset: 'linear-gradient(270deg, #FB9906 0%, #FB01AD 100%)'
    },
    breakpoints: {
        sm: '767px',
        md: '992px'
    }
};
