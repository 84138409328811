import React from 'react';
import {motion} from "framer-motion";

import Button from "../button/Button";
import StepsList from "../../pages/home/subcomponents/StepsList";

import {
    BubbleText,
    CardHeader,
    ContentWrapper,
    Description,
    Paragraph,
    Subtitle,
    Title
} from "./Hero.style";

import cardHeader from "../../resources/img/cards/card-1.svg";
import HIconBig from "../../resources/img/h-white-big.svg";

const variantsSteps = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.4,
            delay: 0.4
        }
    },
}

const variantsTitle = {
    hidden: {
        opacity: 0,
        y: 80
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.4
        }
    },
}

const variantsBubble = {
    hidden: {
        opacity: 0,
        y: 80
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.4,
            delay: 0.4
        }
    },
}

const variantsSubtitle = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.4,
            delay: 0.4
        }
    },
}

const variantsButtonHeader = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.3,
            delay: 0.4
        }
    },
}

const variantsCardHeader = {
    hidden: {
        opacity: 0,
        scale: 0,
    },
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            duration: 0.4,
        }
    },
}

type HeroProps = {
    mini?: boolean,
    buttonCallback: () => void,
    buttonText: () => string
}

const Hero = ({
    buttonCallback,
    buttonText,
    mini,
}: HeroProps) => {
    return (
        <ContentWrapper>
            <Description>
                <Title
                    initial="hidden"
                    animate="visible"
                    variants={variantsTitle}
                >
                    Make your ape talk
                    <BubbleText
                        initial="hidden"
                        animate="visible"
                        variants={variantsBubble}
                    ><h1>hello</h1></BubbleText>
                </Title>

                {!mini && (
                    <>
                        <Subtitle
                            initial="hidden"
                            animate="visible"
                            variants={variantsSubtitle}
                        >Bring your NFT to life using an AI Serum</Subtitle>
                        <Paragraph
                            initial="hidden"
                            animate="visible"
                            variants={variantsButtonHeader}
                        >The Metaverse will be populated by billions of people, we created the first 10,000 voices for them.</Paragraph>
                        <motion.div
                            initial="hidden"
                            animate="visible"
                            variants={variantsButtonHeader}
                        >
                            <Button
                                variant="gradientRainbow"
                                icon={true}
                                onClick={buttonCallback}
                            ><img src={HIconBig} /> {buttonText()}</Button>
                        </motion.div>
                    </>
                )}

                <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={variantsSteps}
                >
                    <StepsList
                        mini={true}
                    />
                </motion.div>
            </Description>
            <CardHeader
                initial="hidden"
                animate="visible"
                variants={variantsCardHeader}
            >
                <img src={cardHeader} alt=""/>
            </CardHeader>
        </ContentWrapper>
    );
};

export default Hero;
