import React from 'react';

import Button from "../../../components/button/Button";
import {
    StepItem,
    StepsListStyled,
    StepsListWrapper,
    Image,
    Number,
    Title,
    Subtitle,
    TitleWrapper,
    OverflowImage
} from './StepsList.style';

import stepImage1 from '../../../resources/img/steps/step-1.png';
import stepImage2 from '../../../resources/img/steps/step-2.svg';
import stepImage3 from '../../../resources/img/steps/step-3.png';

type StepsListType = {
    mini?: boolean,
    handleConnect?: () => void,
    connectButtonText?: () => string
}

const StepsList = ({ mini, handleConnect, connectButtonText }: StepsListType) => {
    return (
        <StepsListWrapper className={`steps-list-wrapper ${mini && 'mini'}`}>
            <StepsListStyled>
                <StepItem className="step-item">
                    <Image src={stepImage1} className="step-image" />
                    <TitleWrapper>
                        <Number className="title-number">1</Number>
                        <Title className="title">Prove ownership</Title>
                    </TitleWrapper>

                    {!mini && (
                        <Subtitle>
                            Confirm that you own an NFT from the Bored Ape Yacht Club collection. The list of compatible NFTs will be expanded soon.
                        </Subtitle>
                    )}
                </StepItem>
                <StepItem className="step-item">
                    <Image src={stepImage2} className="step-image" style={{width: 146}} />
                    <TitleWrapper>
                        <Number className="title-number">2</Number>
                        <Title className="title">Select your NFT</Title>
                    </TitleWrapper>

                    {!mini && (
                        <Subtitle>
                            Select the NFT that you want to animate. As long as it has a face and a mouth, we will bring it to life.
                        </Subtitle>
                    )}
                </StepItem>
                <StepItem className="step-item">
                    <OverflowImage className="overflow-image">
                        <Image src={stepImage3} className="step-image" />
                    </OverflowImage>
                    <TitleWrapper>
                        <Number className="title-number">3</Number>
                        <Title className="title">Make it talk with an AI Serum</Title>
                    </TitleWrapper>
                    {!mini && (
                        <Subtitle>
                            Mix AI technology with NFTs so you can make any NFT speak. Become the voice of a generation.
                        </Subtitle>
                    )}
                </StepItem>
            </StepsListStyled>
            {!mini && connectButtonText && (
                <Button
                    onClick={handleConnect}
                >{connectButtonText()}</Button>
            )}
        </StepsListWrapper>
    );
};

export default StepsList;
