import styled from "styled-components";
import {motion} from "framer-motion";
import {Link} from "react-router-dom";

import {TitleHeading} from "../../styles/general.style";
import {ButtonHeartStyled} from "../button-heart/ButtonHeart.style";

export const WalletWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
  padding-bottom: 22px;
  border-bottom: 1px dashed rgba(255, 255, 255, .35);
  
  ${ButtonHeartStyled} {
    margin-left: 50px;
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    ${ButtonHeartStyled} {
      margin-left: 0;
      margin-top: 10px;
      justify-content: flex-end;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    display: block;
    margin-top: 10px;

    ${ButtonHeartStyled} {
      justify-content: flex-start;
    }
  }
`;

export const WalletAddressStyled = styled(motion(TitleHeading))`
  font-size: 32px;
  line-height: 1.2;
  letter-spacing: -0.55px;
  color: ${props => props.theme.colors.white};
`;

export const Address = styled(motion.span)`
  display: block;
  margin-top: 3px;
  font-family: 'Alliance No.2';
  font-size: 15px;
  color: rgba(255, 255, 255, 0.34);
  letter-spacing: -0.02px;
  overflow: auto;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding-bottom: 5px;
  }
`;

export const GuestText = styled.span`
  position: relative;
  
  &:before {
    content: "Guest";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  
  & > span {
    opacity: 0;
  }
`;

export const UserStats = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    justify-content: flex-start;
    margin-top: 20px;
  }
`;

export const Counters = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: 100%;
    justify-content: flex-end;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    justify-content: flex-start;
  }
`;

export const NFTCount = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  padding-left: 59px;
  
  .image-wrapper {
    left: -65px;
    
    & > img {
      top: -7px;
    }
  }
  
  .count {
    right: -64px;
  }
`;

export const SerumCount = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-left: 107px;
  
  .image-wrapper {
    left: -121px;
  }
  
  .count {
    right: -120px;
  }
`;

export const ImageWrapper = styled.div`
  height: 52px;
  position: relative;
`;

export const Image = styled.img<{ faded?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${props => props.faded ? 0.4 : 1};
`;

export const Count = styled.div`
  position: absolute;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.white};
  border-radius: 50%;
  
  span {
    margin-top: 3px;
    font-size: 13px;
    letter-spacing: -0.22px;
    font-family: 'Avenir LT Std';
    font-weight: bold;
  }
`;

export const Text = styled.p`
  position: relative;
  font-size: 13px;
  letter-spacing: -0.22px;
  color: ${props => props.theme.colors.white};
  z-index: 1;
`;
