import React from 'react';
import {Link, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

import AiSerumStats from "../ai-serum-stats/AISerumStats";

import {selectUser} from "../../store/auth/authSlice";

import {SerumType} from "../../types/voices";

import {
    AISerum,
    AIVoices,
    AIVoicesTitle,
    GetVoice,
    GetVoiceBtn,
    GetVoiceDescription,
    GetVoiceTitle,
    NoVoice,
    VoicesStatsStyled
} from './VoicesStats.style';

import LogoAI from '../../resources/img/logo-ai.png';

const VoicesStats = () => {
    const navigate = useNavigate();
    const user = useSelector(selectUser);
    const firstSerum: SerumType | null = user?.serums.length ? user.serums[0] : null;

    const handleUseSerum = () => {
        navigate('/wallet')
    }

    return (
        <VoicesStatsStyled>
            {user?.serums.length === 0 && (
                <>
                    <AIVoices>
                        <AIVoicesTitle>AI VOICES</AIVoicesTitle>
                        <NoVoice>You don’t own a Voice</NoVoice>
                    </AIVoices>
                    <GetVoice>
                        <GetVoiceTitle>Get your voice</GetVoiceTitle>
                        <GetVoiceDescription>Own evolution</GetVoiceDescription>
                        <GetVoiceBtn>
                            <a href="https://talkens.ai/" target="_blank">
                                <img src={LogoAI} alt=""/>
                                <p>GET YOURS</p>
                            </a>
                        </GetVoiceBtn>
                    </GetVoice>
                </>
            )}
            <AISerum>
                <AiSerumStats serum={firstSerum} handleUse={handleUseSerum} />
            </AISerum>
        </VoicesStatsStyled>
    );
};

export default VoicesStats;
