import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

import btnSunsetBg from '../../resources/img/btn-sunset-bg.png';
import btnRainbowBg from '../../resources/img/bg-btn-rainbow.png';

const variantStyles = (variant = '') =>
    ({
        outline: css`
          border: 1px solid rgba(255, 255, 255, .4);
          background-color: transparent;
        `,
        light: css`
          background: ${props => props.theme.colors.white};
          color: ${props => props.theme.colors.black};
        `,
        dark: css`
          background-color: rgba(255, 255, 255, .15);
        `,
        gradientRainbow: css`
          background-image: ${props => props.theme.gradients.gradientRainbow};

          &:before {
            content: "";
            display: block;
            width: 192.38%;
            position: absolute;
            top: -182%;
            left: 50%;
            transform: translateX(-50%);
            background-image: url(${btnRainbowBg});
            padding-bottom: 131.84%;
            background-size: 100%;
            z-index: -1;
          }
        `,
        gradientSunset: css`
          background-image: ${props => props.theme.gradients.gradientSunset};
          
          &:before {
            content: "";
            display: block;
            width: 166.83%;
            position: absolute;
            top: -97%;
            left: -33%;
            background-image: url(${btnSunsetBg});
            padding-bottom: 91%;
            background-size: 100%;
            opacity: .62;
            z-index: -1;
          }
        `,
    }[variant]);

const typeStyles = (type = '', icon= false) =>
    ({
        v2: css`
          min-width: 211px;
          border-radius: 27px;
          font-weight: 600;
        `,

        v3: css`
          padding-left: 29px;
          padding-right: 29px;
          border-radius: 27px;
          
          p {
            display: flex;
            justify-content: space-between;
            column-gap: 20px;
            margin: 0;
            font-family: 'Avenir LT Std';
            
            span {
              color: rgba(255, 255, 255, 0.37);
            }
          }
        `,
        v4: css`
          min-width: 191px;
          padding-top: 16px;
          padding-bottom: 16px;
          letter-spacing: 0.6px;
          
          > img {
            left: 17px;
          }

          ${icon ? css`padding-left: 49px;` : ''}
        `,
    }[type]);

export const ButtonStyled = styled(Link)<{ variant: string, type: string, icon: boolean }>`
  position: relative;
  display: inline-block;
  min-width: 223px;
  padding: 21.5px 21.5px;
  background-color: ${props => props.theme.colors.blue};
  font-family: 'Avenir LT Std';
  font-weight: 500;
  font-size: 13px;
  color: ${props => props.theme.colors.white};
  border-radius: 16px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  box-sizing: border-box;
  letter-spacing: 0.65px;

  > img {
    position: absolute;
    left: 23px;
    top: 50%;
    transform: translateY(-50%);
  }

  ${({ icon }) => icon ? css`
    padding-left: 56px;
    text-align: left;
  ` : ''}
  
  ${({ variant }) => variantStyles(variant)}
  ${({ type, icon }) => typeStyles(type, icon)}
`;
