import styled from "styled-components";

export const NFTCardStyled = styled.div`
  position: relative;
  width: 297px;
  
  & > a {
    position: absolute;
    bottom: -27px;
    left: 50%;
    transform: translateX(-50%);
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    //width: auto;
  }
`;

export const LogoImage = styled.img`
  position: absolute;
  top: 21px;
  left: 27px;
`;

export const Image = styled.img`
  display: block;
  max-width: 100%;
`;

export const AssetNumber = styled.p`
  position: absolute;
  bottom: 41px;
  left: 32px;
  min-width: 94px;
  margin: 0;
  padding: 10px 47px 8px 17px;
  background-color: ${props => props.theme.colors.white};
  font-family: 'Alliance No.1';
  font-size: 14px;
  letter-spacing: -0.24px;
  border-radius: 16px;
  box-sizing: border-box;
  text-align: left;
  
  span {
    color: ${props => props.theme.colors.pink};
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    //min-width: 88px;
    //padding: 14px 19px 17px 19px;
    //font-size: 17px;
    //border-radius: 25px;
  }
`;
