import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import { motion } from "framer-motion";

import {useModal} from "../../contexts/ModalContext";
import Header from '../../layout/header/Header';
import StepsSerumList from "../../components/steps-serum-list/StepsSerumList";
import Hero from "../../components/hero/Hero";
import Button from "../../components/button/Button";
import AISerumCard from '../../components/ai-serum-card/AISerumCard';

import {
    selectAccounts,
} from "../../store/web3/web3ProviderSlice";
import {selectAuthIsLoading, selectAuthIsLoggedIn} from "../../store/auth/authSlice";

import {Container, TitleSection} from "../../styles/general.style";
import {
    AIVoiceCTA,
    ButtonJoin,
    Caption,
    CaptionText,
    ClaimNFT,
    Description,
    LogoVoices,
    StepsSerumSection,
    WonNFTPage,
} from "./WonNFT.style";

import videoHero from '../../resources/video/hero-movie-v2.mp4';
import logoVoices from '../../resources/img/logo-header.svg';
import btnJoin from '../../resources/img/btn-join.png';

const variantsSteps = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.4,
            delay: 0.4
        }
    },
}

const variantsTitle = {
    hidden: {
        opacity: 0,
        y: 80
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.4
        }
    },
}

const variantsButtonHeader = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.3,
            delay: 0.4
        }
    },
}

const variantsCardHeader = {
    hidden: {
        opacity: 0,
        scale: 0,
    },
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            duration: 0.4,
        }
    },
}

const WonNFT = () => {
    const navigate = useNavigate();
    // @ts-ignore
    const {setModalState} = useModal();
    const accounts = useSelector(selectAccounts);
    const authIsLoading = useSelector(selectAuthIsLoading);
    const isLoggedIn = useSelector(selectAuthIsLoggedIn);

    const handleConnect = () => {
        if(!isLoggedIn) {
            setModalState({isOpen: true});
        } else {
            navigate("/wallet");
        }
    }

    const connectButtonText = () => {
        if(!isLoggedIn) {
            return "Connect wallet";
        } else if(authIsLoading) {
            return "Connecting"
        } else {
            return "Select Apes"
        }
    }

    return (
        <WonNFTPage>
            <Header handleConnect={handleConnect} />
            <Container>
                <ClaimNFT>
                    <Description>
                        <motion.h1
                            initial="hidden"
                            animate="visible"
                            variants={variantsTitle}
                        >You won an AI Serum. <br/>
                            <span className="secondary-text">Use it to <span className="text-underline">make your Ape Talk</span>.</span></motion.h1>

                        <motion.div
                            initial="hidden"
                            animate="visible"
                            variants={variantsButtonHeader}
                        >
                            <Button
                                onClick={handleConnect}
                                variant="gradientRainbow"
                            >Claim it</Button>
                        </motion.div>
                    </Description>
                    <motion.div
                        initial="hidden"
                        animate="visible"
                        variants={variantsCardHeader}
                    >
                        <AISerumCard />
                    </motion.div>
                </ClaimNFT>
                <Hero
                    mini={true}
                    buttonCallback={handleConnect}
                    buttonText={connectButtonText}
                />
            </Container>

            <AIVoiceCTA>
                <video autoPlay={true} loop={true} muted={true} playsInline={true} webkit-playsinline="">
                    <source src={videoHero} type="video/mp4"/>
                </video>
                <Container>
                    <LogoVoices src={logoVoices} />
                    <TitleSection>Bring your NFT to life. <br/>
                        Own your AI Voice NFT.</TitleSection>
                    <Caption>
                        <h3>10.000 Unique Voice AI Serums</h3>
                        <CaptionText>The Metaverse will be populated by billions of people, Humans.ai created the first 10,000 voices for them.</CaptionText>
                        <ButtonJoin to="/">Join wait list <img src={btnJoin} /></ButtonJoin>
                    </Caption>
                </Container>
            </AIVoiceCTA>

            <StepsSerumSection>
                <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={variantsSteps}
                >
                    <StepsSerumList />
                </motion.div>
            </StepsSerumSection>
        </WonNFTPage>
    );
};

export default WonNFT;
