import styled from "styled-components";

import bgVoice from '../../resources/img/bg-voice.png';
import bgBtnGetVoice from '../../resources/img/bg-btn-get-voice.png';
import bgGetVoice from '../../resources/img/bg-get-voice.png';

export const VoicesStatsStyled = styled.div`
  display: flex;
  padding-top: 61px;
  border-top: 1px dashed rgba(255, 255, 255, .35);

  @media (max-width: 1136px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const AIVoices = styled.div`
  position: relative;
  height: 192px;
  width: 246px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 13.5px;
  box-sizing: border-box;
  z-index: 2;
  
  &:before {
    content: "";
    display: block;
    width: 387px;
    height: 372px;
    position: absolute;
    top: -90px;
    left: -56px;
    background-image: url(${bgVoice});
    z-index: 0;
  }
`;

export const AIVoicesTitle = styled.p`
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
  padding: 10px 54px 7px 19px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, .2);
  border-radius: 16px;
  font-size: 13px;
  letter-spacing: -0.22px;
  color: ${props => props.theme.colors.white};
  z-index: 1;
`;

export const NoVoice = styled.p`
  margin: 0 auto;
  font-size: 18px;
  letter-spacing: -0.03px;
  color: ${props => props.theme.colors.white};
  z-index: 1;
`;

export const GetVoice = styled.div`
  position: relative;
  width: 456px;
  margin-left: 55px;
  padding: 48.5px 198px 30px 44px;
  z-index: 1;
  box-sizing: border-box;
  border-radius: 24px;
  
  &:before {
    content: "";
    display: block;
    width: 585px;
    height: 337px;
    background-image: url(${bgGetVoice});
    position: absolute;
    top: -39px;
    right: -60px;
    z-index: -1;
    background-size: cover ;
    background-position: bottom right;
    background-repeat: no-repeat;
  }
  
  &:after {
    content: "";
    display: block;
    width: 664px;
    height: 334px;
    position: absolute;
    top: 20px;
    right: -117px;
    z-index: -2;
    background-image: url(${bgBtnGetVoice});
  }

  @media (max-width: 787px) {
    margin-top: 60px;
    z-index: 2;
  }
`;

export const GetVoiceTitle = styled.h3`
  margin-top: 0;
  margin-bottom: 3.5px;
  font-size: 18px;
  letter-spacing: -0.03px;
  line-height: 1.22;
  color: ${props => props.theme.colors.white};
  font-family: 'Avenir Next LT Pro';
  font-weight: 400;
`;

export const GetVoiceDescription = styled.p`
  margin-top: 0;
  margin-bottom: 14px;
  font-size: 14px;
  line-height: 1.143;
  letter-spacing: -0.02px;
  color: rgba(255, 255, 255, .3);
`;

export const GetVoiceBtn = styled.div`
  position: relative;
  min-width: 223px;
  border: 0.5px solid rgba(255, 255, 255, .7);
  border-radius: 16px;
  box-sizing: border-box;
  
  &:before {
    // content: "";
    // display: block;
    // width: 429px;
    // height: 294px;
    // position: absolute;
    // background-image: url(${bgBtnGetVoice});
    // z-index: -1;
  }
  
  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    padding-top: 17px;
    padding-bottom: 19px;
    padding-left: 23px;
  }
  
  img {
    
  }
  
  p {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 27px;
    font-size: 13px;
    letter-spacing: 0.65px;
    color: ${props => props.theme.colors.white};
  }
`;

export const AISerum = styled.div`
  position: relative;
  margin-top: -38px;
  margin-left: 54px;

  @media (max-width: 1136px) {
    margin-top: 0;
    z-index: 3;
    margin-left: 0;
  }
`;
