import styled from "styled-components";
import {motion} from "framer-motion";

import iconAssetTypeDefault from "../../resources/img/icon-asset-type-default.png";
import CurveArrow from "../../resources/img/curve-arrow.png";
import AssetPlaceholderImage from '../../resources/img/cards/card-placeholder.png';

export const WalletAssets = styled.div`
  position: relative;
  margin-top: 30px;
  z-index: 3;
`;

export const OwnAssets = styled.div`
  display: flex;
  column-gap: 10px;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    flex-direction: column;
  }
`;

export const AvailableAssets = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const AssetsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-left: -5px;
  margin-right: -5px;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    justify-content: center;
  }
`;

export const NoAssets = styled.div`
  width: 254px;
  align-self: center;
  font-size: 23px;
  color: ${props => props.theme.colors.white};
`;

export const TitleList = styled.div`
  width: 100%;
`;

export const TypeList = styled.p`
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  min-width: 120px;
  margin-top: 0;
  margin-bottom: 10px;
  padding: 10.5px 22px 7px 19px;
  border-radius: 16px;
  background-color: ${props => props.theme.colors.white};
  font-size: 13px;
  letter-spacing: -0.22px;
`;

export const Description = styled.p`
  position: relative;
  display: inline-block;
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 18px;
  letter-spacing: -0.03px;
  color: ${props => props.theme.colors.white};
`;

export const PlaceholderImage = styled.div`
  width: 297px;
  height: 297px;
  border: 1px dashed rgba(255, 255, 255, .27);
  border-radius: 16px;
  background-image: url(${AssetPlaceholderImage});
  background-size: contain;
  background-position: bottom center;
`;

export const AssetItem = styled(motion.div)`
  margin-bottom: 45px;
  
  &.our-asset {
    ${TypeList} {
      padding-right: 44px;
      background-image: ${props => props.theme.gradients.gradientRainbow};
      color: ${props => props.theme.colors.white};
      
      &:after {
        content: "";
        display: block;
        height: 34px;
        width: 34px;
        position: absolute;
        right: -44px;
        top: 50%;
        transform: translateY(-50%);
        background-image: url(${iconAssetTypeDefault});
      }
    }
    
    ${Description} {
      &:after {
        content: "";
        display: block;
        height: 84px;
        width: 83px;
        background-image: url(${CurveArrow});
        z-index: 1;
        position: absolute;
        top: 9px;
        right: -93px;
      }
    }
  }
`;

export const AssetItemInner = styled.div`
  position: relative;
  padding-left: 5px;
  padding-right: 5px;
`;

export const FloatingImageStyled = styled.img`
  display: block;
  height: 90px;
  width: 90px;
  position: absolute;
  top: -36px;
  right: 15px;
  z-index: 1;
`;

export const OurAssets = styled.div`
  margin-right: 52px;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    margin-right: 0;
  }
`;
