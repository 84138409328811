import styled from "styled-components";

import {ModalWrapperInner} from "../../styles/modal.style";

export const ModalConnectWalletStyled = styled.div`
  ${ModalWrapperInner} {
    max-width: 620px;
  }
`;

export const WalletList = styled.div`
  display: flex;
  column-gap: 10px;
  justify-content: center;
  padding-left: 15px;
  padding-right: 15px;
`;

export const WalletItem = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-wrap: wrap;
  width: 50%;
  padding: 42px;
  background-color: ${props => props.theme.colors.white};
  border-radius: 45px;
  cursor: pointer;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 10px;
  }
`;

export const Image = styled.img`
  max-width: 70px;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    max-width: 50px;
  }
`;

export const Name = styled.p`
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0;
  font-family: 'Alliance No.2';
  font-size: 21px;
  letter-spacing: -1.15px;
  line-height: 1.714;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 18px;
  }
`;
