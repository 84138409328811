import styled from "styled-components";

export const Line = styled.div`
  width: 100%;
  height: 12px;
  background-color: #F1F1F1;
  border-radius: 27px;
`;

export const Progress = styled.div<{ width: number }>`
  position: relative;
  width: ${props => props.width}%;
  height: 100%;
  transition: 0.1s ease-out width;
  background-image: ${props => props.theme.gradients.gradientSunset};
  border-radius: 27px;
  
  &:after {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    background-image: ${props => props.theme.gradients.gradientSunset};
    border-radius: 27px;
    bottom: -10px;
    transition: 0.1s ease-out width;
    filter: blur(10px);
  }
`;
