import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    TelegramIcon,
    TelegramShareButton,
    TwitterIcon,
    TwitterShareButton
} from "react-share";
import {useLocation, useParams} from "react-router-dom";
import moment from "moment";
import {motion, AnimatePresence} from "framer-motion";
import {Helmet} from "react-helmet";

import ProgressBar from "../../components/progress-bar/ProgressBar";
import Header from "../../layout/header/Header";
import NFTCard from "../../components/nft-card/NFTCard";
import VoicesStats from "../../components/voices-stats/VoicesStats";

import {variantsVoicesStats} from "../wallet/Wallet";

import {
    checkGeneratedApe,
    resetGeneratedApes, selectDefaultApes,
    selectGeneratedApe,
    selectGeneratedApeCallback, sendLinkToEmail
} from "../../store/apes/apesSlice";
import {selectAccounts} from "../../store/web3/web3ProviderSlice";
import {getUser, selectUser} from "../../store/auth/authSlice";

import { Container, CardWrapper } from '../../styles/general.style';
import {
    CardOuter,
    FormEmail,
    Gallery,
    InputWrapper,
    LineWrapper,
    NotifyMsg,
    ShareButton,
    ShareButtonLink,
    Status,
    StatusContent,
    StatusStep,
    StatusText,
    StatusTitle,
    StausBg,
    Title,
} from './Share.style';

import card from '../../resources/img/cards/card-3.png';
import redo from '../../resources/img/redo-arrow.svg';
import play from '../../resources/img/triangle.svg';
import VideoPreview from "../../components/video-preview/VideoPreview";
import {PlayBtn, PlayBtnWrapper, PlayWrapper } from '../../components/video-preview/VideoPreview.style';


const variantsShare = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.4,
            delay: 0.4
        }
    },
}

const variantsVideo = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.4,
            delay: 0.6
        }
    },
}

const variantsShareLink = {
    hidden: {
        opacity: 0,
        y: 30
    },
    visible: (custom: number) => ({
        opacity: 1,
        y: 0,
        transition: {
            type: 'tween',
            duration: 0.3,
            delay: 0.6 + custom * 0.1
        }
    })
}

const variantsProgress = {
    hidden: {
        opacity: 0,
        y: -40
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            type: 'tween',
            duration: 0.3,
        }
    }
}


const Share = () => {
    const dispatch = useDispatch();
    const generatedApe = useSelector(selectGeneratedApe);
    const generatedApeCallback = useSelector(selectGeneratedApeCallback);
    const accounts = useSelector(selectAccounts);
    const location = useLocation();
    const [showAnimations, setShowAnimations] = useState(false);
    const shareTitle = "Talkens NFTs: Make your Bored Ape NFT talk!";

    const shareUrl = window.location.href;
    let domain = (new URL(shareUrl));
    const { taskId } = useParams();
    const [progress, setProgress] = useState(0);
    const [email, setEmail] = useState('');
    const [emailSent, setEmailSent] = useState(false);
    const [emailIsSending, setEmailIsSending] = useState(false);

    useEffect(() => {

        return () => {
            dispatch(resetGeneratedApes());
        }
    }, [])

    useEffect(() => {
        if(taskId) {
            dispatch(checkGeneratedApe({url: `/action/?task=${taskId}`}))
        }

    }, [generatedApeCallback])

    useEffect(() => {
        if(generatedApe && !generatedApe.output && !generatedApe.error) {
            setTimeout(() => {
                dispatch(checkGeneratedApe({url: `/action/?task=${taskId}`}))
            }, 1000)
        }

        if(generatedApe && generatedApe.estimated_end) {
            const percentageElapsed = 100 - percentage(getTimeRemaining(generatedApe.estimated_end), generatedApe.estimated_end - generatedApe.startat_video);
            // console.log(getTimeRemaining(generatedApe.estimated_end), generatedApe.estimated_end - generatedApe.startat_video)
            setProgress(generatedApe.output ? 100 : percentageElapsed);
        }

        if(generatedApe && generatedApe.output && !showAnimations) {
            dispatch(getUser({}))
            setShowAnimations(true);
        }
    }, [generatedApe])

    const getTimeRemaining = (endTime: number) => {
        return endTime - moment().unix();
    }

    const percentage = (partialValue: number, totalValue: number) => {
        return (100 * partialValue) / totalValue;
    }

    const handleUpdateEmail = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setEmailIsSending(true);
        dispatch(sendLinkToEmail({videoHash: taskId, email}))
            // @ts-ignore
            .unwrap()
            .then(() => {
                setEmailSent(true);
                setEmailIsSending(false);
            })
            .catch(() => {
                setEmailIsSending(false);
            });
    }

    return (
        <>
            <Helmet>
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:site" content="@nytimesbits" />
                <meta name="twitter:creator" content="@nickbilton" />
                <meta property="og:url" content="http://bits.blogs.nytimes.com/2011/12/08/a-twitter-for-my-sister/" />
                <meta property="og:title" content="A Twitter for My Sister" />
                <meta property="og:description" content="In the early days, Twitter grew so quickly that it was almost impossible to add new features because engineers spent their time trying to keep the rocket ship from stalling." />
                <meta property="og:image" content="http://graphics8.nytimes.com/images/2011/12/08/technology/bits-newtwitter/bits-newtwitter-tmagArticle.jpg" />
            </Helmet>
            <Header />
            <Container>
                <CardOuter
                    initial="hidden"
                    animate="visible"
                    variants={variantsShare}
                >
                    <CardWrapper>
                        {!generatedApe && <StausBg></StausBg>}
                        {generatedApe && !generatedApe.output ?
                            <>
                                <Status>
                                    <NFTCard
                                        image={generatedApe.apedata.image}
                                        cardNumber={`#${generatedApe.apedata.id}`}
                                        voiceID={generatedApe.voice_id}
                                    />
                                    <StatusContent>
                                        <StatusTitle>Bring it to life…</StatusTitle>
                                        <AnimatePresence>
                                            <StatusStep key="statusStep"><span>{generatedApe.current_step}</span>/{generatedApe.total_steps}</StatusStep>
                                            <StatusText key="statusText">{generatedApe.status_text}</StatusText>
                                            {generatedApe.current_step === 2 &&
											  <motion.div
												initial="hidden"
												animate={"visible"}
												variants={variantsProgress}
                                                key="wrapperProgress"
											  >
												  <LineWrapper>
                                                      <ProgressBar
                                                        percent={progress}
                                                      />
                                                  </LineWrapper>
											  </motion.div>
                                            }
                                        </AnimatePresence>

                                        {!emailSent ? (
                                                <FormEmail onSubmit={handleUpdateEmail}>
                                                    <NotifyMsg>Get notified on email when it’s ready</NotifyMsg>
                                                    <InputWrapper>
                                                        <input
                                                            type="email"
                                                            value={email}
                                                            required
                                                            onChange={e => setEmail(e.target.value)}
                                                            placeholder="Your email"
                                                        />
                                                        <button
                                                            type="submit"
                                                            disabled={emailIsSending}
                                                        >GET NOTIFIED</button>
                                                    </InputWrapper>
                                                </FormEmail>
                                            )
                                            :
                                            <p>Thank you! The email will be sent once processing is done, you can safely close the window, progress will continue in the background</p>
                                        }
                                    </StatusContent>
                                </Status>
                            </>
                            :
                            <>
                                <Gallery>
                                    <motion.div
                                        initial="hidden"
                                        animate={showAnimations ? "visible" : "hidden"}
                                        variants={variantsVideo}
                                    >
                                        <VideoPreview src={generatedApe?.output} />
                                    </motion.div>
                                    {/*<img src={card} alt=""/>*/}
                                </Gallery>
                                <div className="content">
                                    <Title>Share</Title>
                                    {/*<ShareButton style={{backgroundColor: '#FF0076'}}>*/}
                                    {/*    /!*<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">*!/*/}
                                    {/*    /!*    <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/>*!/*/}
                                    {/*    /!*</svg>*!/*/}
                                    {/*    Instagram*/}
                                    {/*</ShareButton>*/}
                                    <ShareButton
                                        style={{backgroundColor: '#00A2FF'}}
                                        initial="hidden"
                                        animate={showAnimations ? "visible" : "hidden"}
                                        variants={variantsShareLink}
                                        custom={0}
                                    >
                                        <TwitterShareButton
                                            url={shareUrl}
                                            title={shareTitle}
                                        >
                                            <TwitterIcon size={74} bgStyle={{fill: "#00A2FF"}} />
                                            Twitter
                                        </TwitterShareButton>
                                    </ShareButton>
                                    <ShareButton
                                        style={{backgroundColor: '#31A9DF'}}
                                        initial="hidden"
                                        animate={showAnimations ? "visible" : "hidden"}
                                        variants={variantsShareLink}
                                        custom={1}
                                    >
                                        <TelegramShareButton
                                            url={generatedApe?.output}
                                            title={shareTitle}
                                        >
                                            <TelegramIcon size={74} bgStyle={{fill: "#31A9DF"}} />
                                            Telegram
                                        </TelegramShareButton>
                                    </ShareButton>
                                    {/*<ShareButton style={{backgroundColor: '#5C6BC0'}}>*/}
                                    {/*    /!*<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">*!/*/}
                                    {/*    /!*    <path d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z"/>*!/*/}
                                    {/*    /!*</svg>*!/*/}
                                    {/*    Discord*/}
                                    {/*</ShareButton>*/}
                                    <ShareButtonLink
                                        type="inverse" to="/wallet"
                                        initial="hidden"
                                        animate={showAnimations ? "visible" : "hidden"}
                                        variants={variantsShareLink}
                                        custom={2}
                                    >
                                        <img src={redo} alt=""/> {accounts.length > 0 ? "Make another one" : "Make your own"}
                                    </ShareButtonLink>
                                </div>
                            </>
                        }
                    </CardWrapper>
                </CardOuter>

                <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={variantsVoicesStats}
                >
                    <VoicesStats />
                </motion.div>
            </Container>
        </>
    );
};

export default Share;
