import React from 'react';

import {
    ButtonHeartInner,
    ButtonHeartStyled,
    IconButton,
    IconHeart,
    Price,
    TextUpper,
    Token
} from './ButtonHeart.style';

import HIconYellow from '../../resources/img/h-yellow.svg';
import iconBtnHeart from '../../resources/img/icon-btn-heart.png';
import iconBtnHeart2 from '../../resources/img/icon-btn-heart2.png';

type ButtonHeartProps = {
    variant?: 'v2',
    textUpper?: boolean,
    count: number
};

const ButtonHeart = ({variant, textUpper, count}: ButtonHeartProps) => {
    return (
        <ButtonHeartStyled
            to="/"
            variant={variant ? variant : ''}
        >
            {textUpper && <TextUpper>SYNTHETIC</TextUpper>}
            <IconButton src={variant === 'v2' ? iconBtnHeart2 : iconBtnHeart} />
            <ButtonHeartInner>
                <IconHeart src={HIconYellow} />
                <Price>{count}</Price>
                <Token>$HEART</Token>
            </ButtonHeartInner>
        </ButtonHeartStyled>
    );
};

export default ButtonHeart;
