import styled from "styled-components";
import {Link} from "react-router-dom";

import line from '../../resources/img/line-ai-serum.png';
import btnMore from '../../resources/img/btn-more.png';

export const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
  width: 90px;
  border: 0.5px solid rgba(255, 255, 255, .77);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, .52);
  cursor: pointer;
`;

export const ControlBtn = styled.img`
  display: block;
`;

export const AiSerumStatsStyled = styled.div`
  position: relative;

  &:before {
    content: "";
    display: block;
    width: 1px;
    height: 212px;
    background-image: url(${line});
    position: absolute;
    left: -6px;
    top: 20px;
  }
`;

export const AISerumBg = styled.img<{active: boolean}>`
  display: block;
  opacity: ${props => props.active ? 1 : .5};
`;

export const SerumIcon = styled.img<{active: boolean}>`
  display: block;
  position: absolute;
  top: 38px;
  right: 33px;
  width: 108px;
  opacity: ${props => props.active ? 1 : .27};
`;

export const AISerumTitle = styled.h3`
  display: inline-block;
  position: absolute;
  left: -29px;
  top: 41px;
  margin-top: 0;
  margin-bottom: 0;
  padding: 10px 51px 7px 17px;
  box-sizing: border-box;
  background-color: ${props => props.theme.colors.white};
  border-radius: 16px;
  font-size: 13px;
  letter-spacing: -0.22px;
  color: ${props => props.theme.colors.black};
  z-index: 1;
`;

export const SerumId = styled.p`
  position: absolute;
  top: 165px;
  left: 41px;
  margin: 0;
  font-size: 13px;
  color: rgba(255, 255, 255, .45);
  letter-spacing: -0.22px;
  
  span {
    display: block;
    font-size: 23px;
    letter-spacing: -0.4px;
    color: ${props => props.theme.colors.white};
  }
`;

export const BuySerumWrapper = styled.div`
  position: absolute;
  top: 47%;
  width: 100%;
  text-align: center;
  z-index: 1;
  
  p {
    margin-top: 0;
    margin-bottom: 33px;
    font-family: 'Avenir LT Std',serif;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: -0.03px;
    color: ${props => props.theme.colors.white};;
  }
`;

export const SerumBtn = styled.div`
  position: absolute;
  bottom: 14px;
  left: 51%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  width: 108px;
  height: 34px;
  padding-top: 10px;
  padding-bottom: 7px;
  border: 0.5px dashed ${props => props.theme.colors.white};
  border-radius: 16px;
  cursor: pointer;
  box-sizing: border-box;
  font-family: 'Avenir Next LT Pro';
  font-weight: 400;
  font-size: 13px;
  letter-spacing: -0.22px;
  color: ${props => props.theme.colors.white};
`;

export const BuyMoreBtn = styled(Link)`
  display: block;
  height: 90px;
  width: 90px;
  background-image: url(${btnMore});
  position: absolute;
  right: -59px;
  top: 79px;
`;

export const HeartCount = styled.p`
  position: absolute;
  top: 183px;
  left: 244px;
  display: inline-block;
  margin: 0;
  font-size: 13px;
  color: rgba(255, 255, 255, .34);
  letter-spacing: -0.22px;
  text-align: center;
  
  span {
    display: block;
    margin-bottom: 3px;
    font-size: 14px;
    letter-spacing: -0.24px;
    color: ${props => props.theme.colors.white};
  }
`;

export const IconCount = styled.p`
  position: absolute;
  top: 39px;
  right: 33px;
  height: 34px;
  width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.black};
  border-radius: 50%;
  font-family: 'Avenir LT Std',serif;
  font-weight: bold;
  font-size: 19px;
  letter-spacing: -0.33px;
`;
