import React from 'react';

import Button from "../button/Button";

import {
    AssetNumber,
    Image,
    LogoImage,
    NFTCardStyled
} from "./NFTCard.style";

import LogoDefaultNFT from '../../resources/img/logo-default-nft.svg';

type NFTCardTypeProps = {
    type?: 'nft' | 'ai-nft'
    hasButton?: boolean,
    buttonText?: string,
    buttonClick?: () => void,
    buttonLink?: string,
    image: string,
    cardNumber?: string,
    voiceID?: string
};

const NFTCard = (
    {
        type = 'ai-nft',
        hasButton,
        buttonText,
        buttonLink,
        buttonClick,
        image,
        cardNumber,
        voiceID
}: NFTCardTypeProps) => {
    return (
        <NFTCardStyled>
            {type === 'ai-nft' && <LogoImage src={LogoDefaultNFT} />}
            <Image src={image} />
            {cardNumber && <AssetNumber>{cardNumber} {type === 'ai-nft' && (<><span>x</span> #{voiceID}</>)}</AssetNumber>}

            {hasButton && buttonText &&
			  <Button
				children={buttonText || ''}
				link={buttonLink}
                onClick={buttonClick}
                variant={type === 'nft' ? 'gradientSunset' : 'light'}
                type="v2"
			  />
            }
        </NFTCardStyled>
    );
};

export default NFTCard;
