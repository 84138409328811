import styled from "styled-components";

import { ModalWrapperInner } from "../../../../styles/modal.style";
import {ButtonStyled} from "../../../../components/button/Button.style";

export const ModalConfirmStyled = styled.div`
  ${ModalWrapperInner} {
    top: 78px;
  }
`;

export const ConfirmActionWrapper = styled.div`
  position: relative;
  max-width: 533px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 29px;
  padding-bottom: 59px;
  background-color: ${props => props.theme.colors.black};
  border-radius: 45px;
  border: 0.5px solid rgba(255, 255, 255, .36);
  box-sizing: border-box;
  box-shadow: 0 2px 31px rgba(0,0,0, 0.5);
  
  h3 {
    margin: 0;
    font-size: 18px;
    letter-spacing: -0.03px;
    color: ${props => props.theme.colors.white};
  }
  
  ${ButtonStyled} {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -29px;
  }
`;
