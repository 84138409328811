import React, {useState} from 'react';
import {useSelector} from "react-redux";

import NFTCard from "../../../../components/nft-card/NFTCard";
import AiSerumStats from "../../../../components/ai-serum-stats/AISerumStats";

import {selectUser} from "../../../../store/auth/authSlice";

import {ApeType} from "../../../../types/apes";
import {SerumType} from "../../../../types/voices";

import {ButtonClose, ModalContent, ModalStyled, ModalTitle, ModalWrapperInner } from '../../../../styles/modal.style';

import {
    AISerumList, ButtonMore, ModalGenerateStyled,
    SerumWrapper
} from './ModalGenerate.style';

import iconClose from '../../../../resources/img/icon-close.png';

type ModalGenerateProps = {
    ape: ApeType,
    isOpen: boolean,
    closeModal: () => void,
    handleUse: (serum: SerumType | null) => void,
};

const ModalGenerate = ({ape, isOpen, closeModal, handleUse}: ModalGenerateProps) => {
    const user = useSelector(selectUser);
    const serumCount = user?.serums.length;
    const lastSerumAvailable = user ? (user.serums[user.serums.length - 1].total - user.serums[user.serums.length - 1].used) : 0;

    const renderSerums = () => {
        return user?.serums.map((serum: SerumType) => (
            <AiSerumStats key={serum.voice_id} serum={serum} handleUse={serum.canuse ? handleUse : undefined} />
        ));
    }

    return (
        <ModalGenerateStyled>
            <ModalStyled state={isOpen ? "show" : ""}>
                <ModalWrapperInner state={isOpen ? "show" : ""}>
                    <ButtonClose
                        src={iconClose}
                        onClick={closeModal}
                    />
                    <ModalTitle>Bring it to Life</ModalTitle>
                    <ModalContent>
                        <SerumWrapper>
                            <NFTCard
                                type="nft"
                                image={ape.image}
                                cardNumber={`#${ape.id}`}
                            />
                            <AISerumList>
                                {serumCount && renderSerums()}
                                {lastSerumAvailable > 0 && <ButtonMore to="/" />}
                            </AISerumList>
                        </SerumWrapper>
                    </ModalContent>
                </ModalWrapperInner>
            </ModalStyled>
        </ModalGenerateStyled>
    );
};

export default ModalGenerate;
