import axios from "axios";
import authHeader from "../../auth/services/auth-header";
import {ApeType} from "../../../types/apes";
import {SerumType} from "../../../types/voices";

const API_URL = "https://apes-api.humans.ai";

const getDefaultApes = () => {
    return axios
        .get(API_URL + '/v1/defaults/')
        .then((response: any) => {
            console.log({response})

            return response.data;
        });
};

const generateApe = (apedata: ApeType, text: string) => {
    return axios
        .post(API_URL + '/action/', {apedata, text}, {
            // @ts-ignore
            headers: authHeader()
        })
        .then((response: any) => {
            console.log({response})
            return response.data;
        });
};


const mintAINFT = (nftrawdata: ApeType, serumdata: SerumType) => {
    return axios
        .post(API_URL + '/mint/', {nftrawdata, serumdata}, {
            // @ts-ignore
            headers: authHeader()
        })
        .then((response: any) => {
            console.log({response})
            return response.data;
        });
};

const checkGeneratedApe = (url: string) => {
    return axios
        .get(API_URL + url, {
            // @ts-ignore
            headers: authHeader()
        })
        .then((response: any) => {
            console.log({response})
            return response.data;
        });
}

const sendLinkToEmail = (videoHash: string, email: string) => {
    return axios
        .put(API_URL + `/action/${videoHash}/`, {email}, {
            // @ts-ignore
            headers: authHeader()
        })
        .then((response: any) => {
            console.log({response})
            return response.data;
        });
};

const getMyVideos = () => {
    return axios
        .get(API_URL + '/v1/myvideos/', {
            // @ts-ignore
            headers: authHeader()
        })
        .then((response: any) => {
            return response.data;
        });
}

const apesService = {
    getDefaultApes,
    generateApe,
    mintAINFT,
    checkGeneratedApe,
    sendLinkToEmail,
    getMyVideos
};
export default apesService;
