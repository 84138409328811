import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "../message/messageSlice";
import AuthService from "./services/auth.service";
import {UserType} from "../../types/user";

const accessTokenRaw = localStorage.getItem('accessToken');
const accessToken = accessTokenRaw ? JSON.parse(accessTokenRaw) : null;

export const getNonce = createAsyncThunk(
    "auth/getNonce",
    async ({publicaddress}: any, thunkAPI) => {
        try {
            const data = await AuthService.getNonce(publicaddress);
            return { nonce: data };
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const sendSignedNonceMsg = createAsyncThunk(
    "auth/sendSignedNonceMsg",
    async ({publicaddress, signature}: any, thunkAPI) => {
        try {
            const data = await AuthService.sendSignedNonceMsg(publicaddress, signature);
            return { token: data };
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const getUser = createAsyncThunk(
    "auth/user",
    async ({}: any, thunkAPI) => {
        try {
            const data = await AuthService.getUser();
            return { user: data };
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue(message);
        }
    }
);

type stateType = {
    isInitialized: boolean,
    isLoggedIn: boolean,
    accessToken: string,
    isLoading: boolean,
    user: null | UserType
};

const initialState: stateType = {
    isInitialized: false,
    isLoggedIn: accessToken ? true : false,
    accessToken: accessToken,
    isLoading: false,
    user: null
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        logoutUser: (state) => {
            state.isLoggedIn = false;
            AuthService.logout();
        }
    },
    extraReducers: (builder => {
        builder
            .addCase(getNonce.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(getNonce.rejected, (state, action) => {
                state.isLoading = false;
            })
            .addCase(sendSignedNonceMsg.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isLoggedIn = true;
                state.accessToken = action.payload.token;
            })
            .addCase(sendSignedNonceMsg.rejected, (state, action) => {
                state.isLoading = false;
            })
            .addCase(getUser.fulfilled, (state, action) => {
                state.user = action.payload.user;
            })
    })
});

export const selectAuthIsInitialized = (state: any) => state.auth.isInitialized;
export const selectAuthIsLoading = (state: any) => state.auth.isLoading;
export const selectAccessToken = (state: any) => state.auth.accessToken;
export const selectAuthIsLoggedIn = (state: any) => state.auth.isLoggedIn;
export const selectUser = (state: any): stateType["user"] => state.auth.user;

const { reducer } = authSlice;
const { actions } = authSlice;

export const { logoutUser } = actions;
export default reducer;
