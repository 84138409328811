import React, {ReactNode} from 'react';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

import Button from "../../components/button/Button";

import {selectAuthIsLoggedIn, selectUser} from "../../store/auth/authSlice";

import {Container} from "../../styles/general.style";
import {Actions, GetVoiceBtn, LogoHeader, MyVideosLink, Nav } from './Header.style';

import LogoHeaderImage from '../../resources/img/logo-header.svg';
import HIcon from '../../resources/img/h-white.svg';
import WalletAddress from "../../components/wallet-address/WalletAddress";

const variantsNav = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.4
        }
    },
}

type HeaderProps = {
    handleConnect?: () => void,
};

const Header = ({handleConnect}: HeaderProps) => {
    const isLoggedIn = useSelector(selectAuthIsLoggedIn);

    return (
        <Container>
            <Nav
                initial="hidden"
                animate="visible"
                variants={variantsNav}
            >
                <Link to="/"><LogoHeader src={LogoHeaderImage} /></Link>
                <Actions>
                    {isLoggedIn && (
                        <MyVideosLink to="/my-videos">MY VIDEOS</MyVideosLink>
                    )}
                    <GetVoiceBtn href="https://talkens.ai/" target="_blank">
                        <Button
                            variant="outline"
                            type="v4"
                            onClick={() => {}}
                        >Get a voice</Button>
                    </GetVoiceBtn>
                    {!isLoggedIn && (
                        <Button
                            variant="dark"
                            type="v4"
                            icon={true}
                            onClick={handleConnect}
                        ><img src={HIcon} /> CONNECT WALLET</Button>
                    )}
                </Actions>
            </Nav>
            {isLoggedIn && <WalletAddress />}
        </Container>
    );
};

export default Header;
