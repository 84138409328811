import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {motion} from "framer-motion";

import Header from "../../layout/header/Header";
import NFTCard from "../../components/nft-card/NFTCard";
import VoicesStats from "../../components/voices-stats/VoicesStats";
import ModalGenerate from "../generate/subcomponents/modal-generate/ModalGenerate";
import ModalConfirm from "../generate/subcomponents/modal-confirm/ModalConfirm";
import ModalNFTGenerated from "../generate/subcomponents/modal-nft-generated/ModalNFTGenerated";

import {
    mintAINFT, resetMintedAINFT,
    selectDefaultApes, selectDefaultApesIsLoading, selectMintedAINFT,
} from "../../store/apes/apesSlice";
import {selectUser} from "../../store/auth/authSlice";

import {AINFTType, ApeType} from "../../types/apes";
import {UserType} from "../../types/user";
import {SerumType} from "../../types/voices";

import {
    Container,
} from "../../styles/general.style";

import {
    AssetsList,
    WalletAssets,
    AssetItem,
    OurAssets,
    AssetItemInner,
    NoAssets,
    AvailableAssets,
    TitleList,
    TypeList,
    Description,
    FloatingImageStyled,
    PlaceholderImage,
} from './Wallet.style';

import FloatingImage from '../../resources/img/steps/step-3.png';

const variantsAssets = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.4,
            delay: 0.4
        }
    },
}

const variantsAssetItem = {
    hidden: {
        opacity: 0,
        y: 50
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.4,
            delay: 0.4
        }
    },
}

export const variantsVoicesStats = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.4,
            delay: 0.8
        }
    },
}

const Wallet = () => {
    const dispatch = useDispatch();
    const defaultApes = useSelector(selectDefaultApes);
    const defaultApesIsLoading = useSelector(selectDefaultApesIsLoading);
    const user: UserType | null = useSelector(selectUser);
    const mintedAINFT = useSelector(selectMintedAINFT);

    const [apeToGenerate, setApeToGenerate] = useState<null | ApeType>(null);
    const [serumToUse, setSerumToUse] = useState<null | SerumType>(null);

    const [isOpenGenerateModal, setIsOpenGenerateModal] = useState(false);
    const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
    const [isOpenNFTGeneratedModal, setIsOpenNFTGeneratedModal] = useState(false);

    useEffect(() => {
        return () => {
            closeNFTGeneratedModal();
        }
    }, [])

    useEffect(() => {
        if(apeToGenerate) {
            setIsOpenGenerateModal(true);
        }
    }, [apeToGenerate])

    useEffect(() => {
        if(mintedAINFT) {
            setIsOpenNFTGeneratedModal(true);
        }
    }, [mintedAINFT]);

    const closeGenerateModal = (resetApe: boolean = false) => {
        setIsOpenGenerateModal(false);
        if(resetApe) {
            setApeToGenerate(null)
        }
    }

    const closeConfirmModal = () => {
        setIsOpenConfirmModal(false);
        setTimeout(() => {
            setApeToGenerate(null);
        }, 500)
    }

    const closeNFTGeneratedModal = () => {
        setIsOpenNFTGeneratedModal(false);
        dispatch(resetMintedAINFT());
    }

    const generateOwned = (ape: ApeType) => {
        setApeToGenerate(ape);
    }

    const handleUse = (serum: SerumType | null) => {
        closeGenerateModal(false);
        setSerumToUse(serum);
        setIsOpenConfirmModal(true);
    }

    const handleConfirm = () => {
        closeConfirmModal();
        if(apeToGenerate && serumToUse) {
            dispatch(mintAINFT({
                nftrawdata: apeToGenerate,
                serumdata: serumToUse
            }));
        }
    }

    const renderDefaultApes = () => {
        if(defaultApes.length > 0) {
            return defaultApes.map((ape, index) => (
                <>
                    <AssetItem
                        key={ape.nft_reference.id}
                        className="our-asset"
                        initial="hidden"
                        animate="visible"
                        variants={variantsAssetItem}
                    >
                        {index === 0 && (
                            <TitleList>
                                <TypeList>AI NFTs</TypeList><br/>
                                <Description>Use our AI NFT</Description>
                            </TitleList>
                        )}
                        <AssetItemInner>
                            {index === 0 && (
                                <FloatingImageStyled src={FloatingImage} />
                            )}
                            <OurAssets>
                                <NFTCard
                                    hasButton={true}
                                    buttonText="Make it talk"
                                    buttonLink={`/generate/${ape.nft_reference.id}`}
                                    image={ape.nft_image}
                                    cardNumber={`#${ape.nft_reference.id}`}
                                    voiceID={ape.voice_id}
                                />
                            </OurAssets>
                        </AssetItemInner>
                    </AssetItem>
                </>
            ));
        }
    }

    const renderAINFTs = () => {
        if(user && user.ainfts.length > 0) {
            return user.ainfts.map((ape, index) => (
                <>
                    <AssetItem
                        key={ape.nft_reference.id}
                        className="our-asset"
                        initial="hidden"
                        animate="visible"
                        variants={variantsAssetItem}
                    >
                        <AssetItemInner>
                            {index === 0 && (
                                <FloatingImageStyled src={FloatingImage} />
                            )}
                            <OurAssets>
                                <NFTCard
                                    hasButton={true}
                                    buttonText="Make it talk"
                                    buttonLink={`/generate/${ape.nft_reference.id}`}
                                    image={ape.nft_image}
                                    cardNumber={`#${ape.nft_reference.id}`}
                                    voiceID={ape.voice_id}
                                />
                            </OurAssets>
                        </AssetItemInner>
                    </AssetItem>
                </>
            ));
        }
    }

    const renderOwnedApes = () => {
        if(user && user.nfts?.bayc.length > 0) {
            return user.nfts.bayc.map((ape, index) => (
                <>
                    <AssetItem
                        key={ape.id}
                        className="own-asset"
                        initial="hidden"
                        animate="visible"
                        variants={variantsAssetItem}
                    >
                        {index === 0 && (
                            <TitleList>
                                <TypeList>YOUR NFTs</TypeList><br/>
                                <Description>{user.nfts.bayc.length} Supported NFTs</Description>
                            </TitleList>
                        )}
                        <AssetItemInner>
                            <NFTCard
                                type="nft"
                                hasButton={true}
                                buttonText="BRING IT TO LIFE"
                                buttonClick={() => generateOwned(ape)}
                                image={ape.image}
                                cardNumber={`#${ape.id}`}
                            />
                        </AssetItemInner>
                    </AssetItem>
                </>
            ));
        } else return (
            <AssetItem
                className="own-asset"
                initial="hidden"
                animate="visible"
                variants={variantsAssetItem}
                key="no-asset"
            >
                <TitleList>
                    <TypeList>YOUR NFTs</TypeList><br/>
                    <Description>You don’t own any supported NFTs</Description>
                </TitleList>
                <AssetItemInner>
                    <PlaceholderImage/>
                </AssetItemInner>
            </AssetItem>
        );
    }

    return (
        <>
            <Header />
            <Container>
                <WalletAssets>
                    <motion.div
                        initial="hidden"
                        animate="visible"
                        variants={variantsAssets}
                    >
                        {defaultApesIsLoading ?
                            <h2 style={{color: '#fff'}}>Loading your apes</h2>
                            :
                            <>
                                <AvailableAssets>
                                    <AssetsList>
                                        {renderDefaultApes()}
                                        {renderAINFTs()}
                                        {renderOwnedApes()}
                                    </AssetsList>
                                </AvailableAssets>
                            </>
                        }
                    </motion.div>
                </WalletAssets>

                <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={variantsVoicesStats}
                >
                    <VoicesStats />
                </motion.div>
            </Container>
            {apeToGenerate &&
              <ModalGenerate
                ape={apeToGenerate}
                isOpen={isOpenGenerateModal}
                closeModal={closeGenerateModal}
                handleUse={handleUse}
              />
            }
            <ModalConfirm
                isOpen={isOpenConfirmModal}
                message={`Use AI Serum #${serumToUse?.voice_id} with NFT #${apeToGenerate?.id}`}
                closeModal={closeConfirmModal}
                handleConfirm={handleConfirm}
            />

            {mintedAINFT?.nft_reference &&
			  <ModalNFTGenerated
				ape={mintedAINFT.nft_reference}
				isOpen={isOpenNFTGeneratedModal}
				closeModal={closeNFTGeneratedModal}
			  />
            }
        </>
    );
};

export default Wallet;
