import styled from "styled-components";

import {ButtonHeartStyled} from "../button-heart/ButtonHeart.style";

import logoWhite from '../../resources/img/logo-header.svg';

export const AiSerumCardStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  ${ButtonHeartStyled} {
    position: relative;
    margin-top: -26px;
    z-index: 2;
  }
`;

export const SerumInfo = styled.div`
  position: relative;
  width: 394px;
  border: 3.5px solid ${props => props.theme.colors.white};
  border-radius: 43px;
  box-sizing: border-box;
  overflow: hidden;
  
  &:after {
    content: "";
    display: block;
    height: 28px;
    width: 120px;
    position: absolute;
    right: 44.5px;
    bottom: 40.5px;
    background-image: url(${logoWhite});
    background-size: 100%;
    background-repeat: no-repeat;
  }
`;

export const CardBg = styled.img`
  display: block;
  max-width: 100%;
`;

export const Title = styled.p`
  position: absolute;
  top: 20.5px;
  left: 15.5px;
  margin: 0;
  padding: 19px 56px 11px 31px;
  border: 1px solid rgba(255, 255, 255, .31);
  border-radius: 43px;
  font-size: 15px;
  letter-spacing: 7.59px;
  color: ${props => props.theme.colors.white};
`;

export const ID = styled.p`
  position: absolute;
  bottom: 28.5px;
  left: 35.5px;
  margin: 0;
  font-size: 29px;
  letter-spacing: -0.5px;
  color: ${props => props.theme.colors.white};
`;

export const Count = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 37px;
  width: 37px;
  position: absolute;
  top: 54px;
  right: 39.5px;
  margin: 0;
  background-color: ${props => props.theme.colors.white};
  border-radius: 50%;
  font-family: 'Avenir LT Std';
  font-weight: bold;
  font-size: 19px;
  letter-spacing: -0.33px;
  color: ${props => props.theme.colors.black};
`;
