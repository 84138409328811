import styled from "styled-components";

import { ModalWrapperInner } from "../../../../styles/modal.style";
import {ButtonHeartStyled} from "../../../../components/button-heart/ButtonHeart.style";
import {LogoImage} from "../../../../components/nft-card/NFTCard.style";

export const ModalNFTGeneratedStyled = styled.div`
  ${ModalWrapperInner} {
    top: 78px;
  }
`;

export const NFTGeneratedWrapper = styled.div`
  display: inline-block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding: 48px;
  background-color: ${props => props.theme.colors.black};
  border-radius: 45px;
  border: 0.5px solid rgba(255, 255, 255, .36);
  box-sizing: border-box;
  box-shadow: 0 2px 31px rgba(0,0,0, 0.5);
  
  ${ButtonHeartStyled} {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 22px;
    z-index: 1;
  }
  
  ${LogoImage} {
    top: 38px;
  }
`;
