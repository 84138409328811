import React, {useEffect} from 'react';
import {motion} from "framer-motion";
import {useDispatch, useSelector} from "react-redux";
import {TelegramIcon, TelegramShareButton, TwitterIcon, TwitterShareButton} from "react-share";

import {getMyVideos, selectMyVideos} from "../../store/apes/apesSlice";

import Header from "../../layout/header/Header";
import VoicesStats from "../../components/voices-stats/VoicesStats";
import VideoPreview from '../../components/video-preview/VideoPreview';

import {variantsVoicesStats} from "../wallet/Wallet";

import {Container, TitleHeading} from '../../styles/general.style';
import {
    FeaturedVideo,
    MyVideo,
    MyVideosStyled,
    ShareBtnIcon,
    ShareBtnsList,
    TextSpoken,
    VideosList
} from './MyVideos.style';
import {AssetNumber} from "../../components/nft-card/NFTCard.style";

export const variantsVideos = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.4,
            delay: 0.4
        }
    },
}

const MyVideos = ({type = 'default'}) => {
    const dispatch = useDispatch();
    const shareTitle = "Humans Life: make your BAYC ape talk";
    const myVideos = useSelector(selectMyVideos);

    useEffect(() => {
        dispatch(getMyVideos({}));
    }, [])

    const renderVideos = () => {
        if(myVideos.length > 0) {
            return myVideos.map((video: any, index: any) => (
                <MyVideo>
                    <FeaturedVideo>
                        <VideoPreview src={video.raw_data.output} />
                        <ShareBtnsList>
                            <ShareBtnIcon
                                style={{backgroundColor: '#00A2FF'}}
                            >
                                <TwitterShareButton
                                    url={video.raw_data.output}
                                    title={shareTitle}
                                    hashtags={["tagexample1", "tagexample2"]}
                                >
                                    <TwitterIcon size={46} bgStyle={{fill: "#00A2FF"}} />
                                </TwitterShareButton>
                            </ShareBtnIcon>
                            <ShareBtnIcon
                                style={{backgroundColor: '#31A9DF'}}
                            >
                                <TelegramShareButton
                                    url={video.raw_data.output}
                                    title={shareTitle}
                                >
                                    <TelegramIcon size={46} bgStyle={{fill: "#31A9DF"}} />
                                </TelegramShareButton>
                            </ShareBtnIcon>
                        </ShareBtnsList>
                    </FeaturedVideo>
                    <AssetNumber>#{video.raw_data.apedata.id} {type === 'default' && (<><span>x</span> #{video.raw_data.voice_id}</>)}</AssetNumber>
                    <TextSpoken>{video.raw_data.text}</TextSpoken>
                </MyVideo>
            ));
        }
    }

    return (
        <MyVideosStyled>
            <Header />

            <Container>
                <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={variantsVideos}
                >
                    <TitleHeading>My videos</TitleHeading>
                    <VideosList>
                        {renderVideos()}
                    </VideosList>
                </motion.div>

                <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={variantsVoicesStats}
                >
                    <VoicesStats />
                </motion.div>
            </Container>
        </MyVideosStyled>
    );
};

export default MyVideos;
