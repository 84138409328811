import styled from "styled-components";
import {motion} from "framer-motion";
import {Link} from "react-router-dom";

import {CardHeader, ContentWrapper} from "../../components/hero/Hero.style";
import {ButtonStyled} from "../../components/button/Button.style";
import {AiSerumCardStyled, SerumInfo} from "../../components/ai-serum-card/AISerumCard.style";

import afterCard from '../../resources/img/after-card.png';
import {Container, Text, TitleSection} from "../../styles/general.style";

export const WonNFTPage = styled.div`
  nav {
    margin-bottom: 106px;
  }
  
  ${CardHeader} {
    margin-top: 30px;
    
    &:after {
      content: none;
    }
  }
  
  ${ContentWrapper} {
    padding-bottom: 161px;
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    nav {
      margin-bottom: 74px;
    }

    ${ContentWrapper} {
      padding-bottom: 112px;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    nav {
      margin-bottom: 50px;
    }

    ${ContentWrapper} {
      padding-bottom: 60px;
    }
  }
`;

export const ClaimNFT = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 81px;

  ${AiSerumCardStyled} {
    position: relative;
    
    &:before {
      content: "";
      display: block;
      width: 86.803%;
      padding-bottom: 89.09%;
      position: absolute;
      top: -30%;
      left: -40%;
      filter: blur(4px);
      opacity: 0.4;
      background-image: url(${afterCard});
      background-size: 100%;
      z-index: 0;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    ${SerumInfo} {
      max-width: 100%;
      width: 100%;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    flex-wrap: wrap;
    margin-bottom: 130px;
    
    ${AiSerumCardStyled} {
      margin-top: 35px;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

export const Description = styled.div`
  position: relative;
  z-index: 1;
  
  h1 {
    display: block;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 43px;
    line-height: 1.093;
    letter-spacing: -0.74px;
    color: ${props => props.theme.colors.gray};
    
    .secondary-text {
      color: ${props => props.theme.colors.white};
    }
    
    .text-underline {
      position: relative;
      
      &:after {
        content: "";
        display: block;
        height: 4px;
        width: 103%;
        position: absolute;
        bottom: -12px;
        right: -27px;
        background-image: ${props => props.theme.gradients.gradientSunset};
      }
    }
  }

  ${ButtonStyled} {
    margin-top: 67px;
    font-size: 23px;
    letter-spacing: -0.4px;
    text-transform: none;
    
    &:before {
      top: -140%;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
    
    h1 {
      font-size: 30px;
    }

    ${ButtonStyled} {
      margin-top: 34px;
    }
  }
`;

export const AIVoiceCTA = styled.div`
  position: relative;

  video {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 0;
  }
  
  ${Container} {
    position: relative;
    padding-top: 150px;
    padding-bottom: 229px;
    border-top: 1px solid rgba(255, 255, 255, .25);
  }

  ${TitleSection} {
    margin-top: 21px;
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    ${Container} {
      padding-top: 90px;
      padding-bottom: 100px;
    }
  }
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    video {
      width: 200%;
      margin-right: -50%;
    }
    
    ${Container} {
      padding-bottom: 60px;
      padding-top: 50px;
    }
  }
`;

export const LogoVoices = styled.img`
  width: 208px;
`;

export const Caption = styled.div`
  max-width: 490px;
  margin-top: 70px;
  margin-left: 17.5%;
  
  h3 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 18px;
    color: ${props => props.theme.colors.white};
    line-height: 2.167;
    letter-spacing: -0.03px;
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    margin-left: 0;
    margin-top: 49px;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    margin-top: 40px;
  }
`;

export const CaptionText = styled(Text)`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  letter-spacing: -0.02px;
  line-height: 1.143;
  color: rgba(255, 255, 255, .3);
`;

export const ButtonJoin = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 22px;
  padding: 8px 12px 9px 42px;
  background-image: ${props => props.theme.gradients.gradientRainbow};
  font-family: 'Avenir LT Std';
  font-weight: 500;
  font-size: 16px;
  letter-spacing: -0.26px;
  color: ${props => props.theme.colors.white};
  border-radius: 49.5px;
  text-decoration: none;
`;

export const StepsSerumSection = styled(motion.div)`
  position: relative;
`;
