import styled from "styled-components";

export const VideoPreviewStyled = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  border-radius: 24px;
  overflow: hidden;
  
  video {
    display: block;
    max-width: 100%;
  }
`;

export const PlayWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

export const PlayBtnWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 18.5%;
  height: 18.5%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.white};
  cursor: pointer;
`;

export const PlayBtn = styled.img`
  display: block;
  width: 23.08%;
`;
