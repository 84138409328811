import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "../message/messageSlice";
import ApesService from "./services/apes.service";
import {AINFTType, ApeType} from "../../types/apes";
import {SerumType} from "../../types/voices";
import {getUser} from "../auth/authSlice";

export const getDefaultApes = createAsyncThunk(
    "apes/getDefaultApes",
    async ({none}: any, thunkAPI) => {
        try {
            const data = await ApesService.getDefaultApes();
            return { defaultApes: data };
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue(message);
        }
    }
);

type generatePayloadType = {
    apedata: ApeType,
    text: string
}

export const generateApe = createAsyncThunk(
    "apes/generateApe",
    async ({apedata, text}: generatePayloadType, thunkAPI) => {
        try {
            const data = await ApesService.generateApe(apedata, text);

            if(data.error) {
                thunkAPI.dispatch(setMessage(data.error));

                return thunkAPI.rejectWithValue(data.error);
            }

            return { generatedApe: data };
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue(message);
        }
    }
);

type mintAINFTPayloadType = {
    nftrawdata: ApeType,
    serumdata: SerumType
}

export const mintAINFT = createAsyncThunk(
    "apes/mintAINFT",
    async ({nftrawdata, serumdata}: mintAINFTPayloadType, thunkAPI) => {
        try {
            const data = await ApesService.mintAINFT(nftrawdata, serumdata);

            // return {mintedAINFT: {
            //     "voice_id": 1,
            //     "nft_reference": {
            //         "id": 7586,
            //         "name": "#7586",
            //         "image": "https://img.seadn.io/files/64f633994828968c7435bf10c53fc0af.png?auto=format"
            //     },
            //     "nft_image": "https://img.seadn.io/files/64f633994828968c7435bf10c53fc0af.png?auto=format"
            // }};
            if(data.error) {
                thunkAPI.dispatch(setMessage(data.error));

                return thunkAPI.rejectWithValue(data.error);
            }

            thunkAPI.dispatch(getUser({}));

            return { mintedAINFT: data };
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const checkGeneratedApe = createAsyncThunk(
    "apes/checkGeneratedApe",
    async ({url}: any, thunkAPI) => {
        try {
            const data = await ApesService.checkGeneratedApe(url);
            return { generatedApe: data };
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const sendLinkToEmail = createAsyncThunk(
    "apes/sendLinkToEmail",
    async ({videoHash, email}: any, thunkAPI) => {
        try {
            const data = await ApesService.sendLinkToEmail(videoHash, email);
            return { sendLinkToEmail: data };
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const getMyVideos = createAsyncThunk(
    "apes/getMyVideos",
    async ({}: any, thunkAPI) => {
        try {
            const data = await ApesService.getMyVideos();
            return { myVideos: data };
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue(message);
        }
    }
);

type initialStateType = {
    defaultApes: AINFTType[] | [],
    defaultApesIsLoading: boolean,
    myVideos: any,
    myVideosIsLoading: boolean,
    generatedApe: any,
    generatedApeIsLoading: boolean,
    generateApeCallback: string | null,
    mintedAINFT: AINFTType | null
};

const initialState: initialStateType = {
    defaultApes: [],
    defaultApesIsLoading: false,
    myVideos: [],
    myVideosIsLoading: false,
    generatedApe: null,
    generatedApeIsLoading: false,
    generateApeCallback: null,
    mintedAINFT: null
};

const apesSlice = createSlice({
    name: "apes",
    initialState,
    reducers: {
        resetGeneratedApes: (state) => {
            state.generatedApe = initialState.generatedApe;
            state.generateApeCallback = initialState.generateApeCallback;
        },
        resetMintedAINFT: (state) => {
            state.mintedAINFT = initialState.mintedAINFT;
        }
    },
    extraReducers: (builder => {
        builder
            .addCase(getDefaultApes.pending, (state, action) => {
                state.defaultApesIsLoading = true;
            })
            .addCase(getDefaultApes.rejected, (state, action) => {
                state.defaultApesIsLoading = false;
            })
            .addCase(getDefaultApes.fulfilled, (state, action) => {
                state.defaultApes = action.payload.defaultApes;
                state.defaultApesIsLoading = false;
            })
            .addCase(getMyVideos.pending, (state, action) => {
                state.myVideosIsLoading = true;
            })
            .addCase(getMyVideos.rejected, (state, action) => {
                state.myVideosIsLoading = false;
            })
            .addCase(getMyVideos.fulfilled, (state, action) => {
                state.myVideos = action.payload.myVideos;
                state.myVideosIsLoading = false;
            })
            .addCase(generateApe.pending, (state, action) => {
                state.generatedApeIsLoading = true;
            })
            .addCase(generateApe.fulfilled, (state, action) => {
                state.generateApeCallback = action.payload.generatedApe.callback;
                state.generatedApeIsLoading = false;
            })
            .addCase(generateApe.rejected, (state, action) => {
                state.generatedApeIsLoading = false;
            })
            .addCase(checkGeneratedApe.fulfilled, (state, action) => {
                state.generatedApe = action.payload.generatedApe;
                if(action.payload.generatedApe.output) {
                    state.generateApeCallback = initialState.generateApeCallback;
                }
            })
            .addCase(mintAINFT.rejected, (state, action) => {

            })
            .addCase(mintAINFT.fulfilled, (state, action) => {
                state.mintedAINFT = action.payload.mintedAINFT.data;

                // state.mintedAINFT = {
                //     nft_image: "https://img.seadn.io/files/64f633994828968c7435bf10c53fc0af.png?auto=format",
                //     nft_reference: {
                //         "name": "#7586",
                //         "id": 7586,
                //         "image": "https://img.seadn.io/files/64f633994828968c7435bf10c53fc0af.png?auto=format"
                //     },
                //     voice_id: 1
                // };
            })
    })
});

export const { resetGeneratedApes, resetMintedAINFT } = apesSlice.actions;

export const selectDefaultApes = (state: any): initialStateType["defaultApes"] => state.apes.defaultApes;
export const selectMyVideos = (state: any): initialStateType["myVideos"] => state.apes.myVideos;
export const selectDefaultApesIsLoading = (state: any) => state.apes.defaultApesIsLoading;
export const selectGeneratedApeIsLoading = (state: any) => state.apes.generatedApeIsLoading;
export const selectGeneratedApe = (state: any): initialStateType["generatedApe"] => state.apes.generatedApe;
export const selectGeneratedApeCallback = (state: any) => state.apes.generateApeCallback;
export const selectMintedAINFT = (state: any): initialStateType["mintedAINFT"] => state.apes.mintedAINFT;

const { reducer } = apesSlice;
export default reducer;
