import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import { motion } from "framer-motion";

import {useModal} from "../../contexts/ModalContext";
import Button from "../../components/button/Button";
import StepsList from "./subcomponents/StepsList";
import Header from '../../layout/header/Header';
import StepsSerumList from "../../components/steps-serum-list/StepsSerumList";
import Hero from "../../components/hero/Hero";

import {
    selectAccounts,
} from "../../store/web3/web3ProviderSlice";
import {selectAuthIsLoading, selectAuthIsLoggedIn} from "../../store/auth/authSlice";

import {Container} from "../../styles/general.style";
import {
    Banner,
    BannerContent,
    BannerImage,
    BannerTitle,
    HomePage,
    StepsSerumSection,
} from "./Home.style";

import banner from "../../resources/img/banner.svg";
import HIconDark from "../../resources/img/h-black.svg";

const variantsSteps = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.4,
            delay: 0.4
        }
    },
}

const Home = () => {
    const navigate = useNavigate();
    // @ts-ignore
    const {setModalState} = useModal();
    const accounts = useSelector(selectAccounts);
    const authIsLoading = useSelector(selectAuthIsLoading);
    const isLoggedIn = useSelector(selectAuthIsLoggedIn);

    useEffect(() => {
        // setModalState({isOpen: false});
    }, [accounts]);

    const handleConnect = () => {
        if(!isLoggedIn) {
            setModalState({isOpen: true});
        } else {
            navigate("/wallet");
        }
    }

    const connectButtonText = () => {
        if(!isLoggedIn) {
            return "Connect wallet";
        } else if(authIsLoading) {
            return "Connecting"
        } else {
            return "Select Apes"
        }
    }

    return (
        <HomePage>
            <Header handleConnect={handleConnect} />
            <Container>
                <Hero
                    buttonCallback={handleConnect}
                    buttonText={connectButtonText}
                />
                <Banner
                    initial="hidden"
                    animate="visible"
                    variants={variantsSteps}
                >
                    <BannerContent>
                        <BannerTitle>Don’t have an ape? <br/> Use ours.</BannerTitle>
                        <Button
                            onClick={handleConnect}
                            variant="light"
                            icon={true}
                        ><img src={HIconDark} /> {connectButtonText()}</Button>
                    </BannerContent>
                    <BannerImage src={banner} />
                </Banner>
            </Container>

            <StepsSerumSection>
                <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={variantsSteps}
                >
                    <StepsSerumList />
                </motion.div>
            </StepsSerumSection>

            <Container>
                <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={variantsSteps}
                >
                    <StepsList
                        handleConnect={handleConnect}
                        connectButtonText={connectButtonText}
                    />
                </motion.div>
            </Container>
        </HomePage>
    );
};

export default Home;
