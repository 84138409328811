import React, {useRef, useState} from 'react';

import {
    VideoPreviewStyled,
    PlayWrapper,
    PlayBtnWrapper,
    PlayBtn
} from './VideoPreview.style';

import play from "../../resources/img/triangle.svg";

type VideoPreviewProps = {
    src: string
};

const VideoPreview = ({src}: VideoPreviewProps) => {
    const videoElem = useRef(null);
    const [playing, setPlaying] = useState(false);
    const [showControls, setShowControls] = useState(false);

    const handlePlay = () => {
        setPlaying(true);
        // @ts-ignore
        videoElem.current.play();
    }

    return (
        <VideoPreviewStyled
            onMouseEnter={() => setShowControls(true)}
            onMouseLeave={() => setShowControls(false)}
        >
            <video
                ref={videoElem}
                src={src}
                controls={showControls}>
            </video>
            {!playing && (
                <PlayWrapper onClick={handlePlay}>
                    <PlayBtnWrapper>
                        <PlayBtn src={play} alt="Play"/>
                    </PlayBtnWrapper>
                </PlayWrapper>
            )}
        </VideoPreviewStyled>
    );
};

export default VideoPreview;
