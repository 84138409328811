import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import { motion } from 'framer-motion';

import Header from "../../layout/header/Header";
import Button from "../../components/button/Button";
import NFTCard from "../../components/nft-card/NFTCard";
import VoicesStats from "../../components/voices-stats/VoicesStats";

import {variantsVoicesStats} from "../wallet/Wallet";

import {countWords} from "../../utils/strings";

import {
    generateApe,
    selectDefaultApes,
    selectGeneratedApeCallback,
    selectGeneratedApeIsLoading
} from "../../store/apes/apesSlice";
import {selectUser} from "../../store/auth/authSlice";
import {setMessage} from "../../store/message/messageSlice";

import { Container, CardWrapper } from '../../styles/general.style';
import {
    FormWrapper,
    Gallery,
    LogoImage,
    Title,
    SelectContainer,
    DropdownWrapper,
    Textarea,
    SubmitWrapper,
    DropdownButton,
    DropdownLogo,
    PlayButton,
    ArrowDown, NameVoice, DropdownContent, ButtonForm, WordPrice,
} from './Generate.style';

import logo from "../../resources/img/logo-black.svg";
import serumImg from '../../resources/img/steps/step-3.png';
import triangle from "../../resources/img/triangle.svg";
import arrowDown from "../../resources/img/arrow-down.svg";
import banner from "../../resources/img/banner-voice.png";
import iconPause from "../../resources/img/icon-pause-black.png";
import audioSample from "../../resources/file_example_MP3_1MG.mp3";

const variantsGenerate = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.4,
            delay: 0.4
        }
    },
}

const Generate = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [text, setText] = useState('');
    const user = useSelector(selectUser);
    const [wordCount, setWordCount] = useState(0);

    const generatedApeIsLoading = useSelector(selectGeneratedApeIsLoading);
    const generatedApeCallback = useSelector(selectGeneratedApeCallback);
    const defaultApes = useSelector(selectDefaultApes);
    const { id } = useParams();
    const defaultApe = defaultApes.find(ape => ape.nft_reference.id === Number(id));
    const ownedApe = user?.ainfts.find(ape => ape.nft_reference.id === Number(id));
    const selectedApe = ownedApe || defaultApe;
    const selectedSerum = user?.serums.find(serum => serum.voice_id === selectedApe?.voice_id);
    const [audio] = useState(new Audio(audioSample));
    const [playing, setPlaying] = useState(false);

    const getTaskId = (url: string) => {
        const idKey = "task=";
        return url.substring(generatedApeCallback.indexOf(idKey) + idKey.length);
    }

    useEffect(() => {
        if(generatedApeCallback) {
            navigate(`/share/${getTaskId(generatedApeCallback)}`)
        }
    }, [generatedApeCallback, navigate])

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    }

    useEffect(() => {
            playing ? audio.play() : audio.pause();
        },
        [playing]
    );

    useEffect(() => {
        audio.addEventListener('ended', () => setPlaying(false));
        return () => {
            audio.removeEventListener('ended', () => setPlaying(false));
            audio.pause();
        };
    }, []);

    const handleGenerate = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if(user && user.balance < wordCount) {
            dispatch(setMessage("You don't have enough $HEART"))
            return;
        }
        if(generatedApeIsLoading) return;

        if(selectedApe && text) {
            dispatch(generateApe({apedata: selectedApe.nft_reference, text}));
        }
    }

    const handleTextInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = event.target.value;
        setText(value);
        setWordCount(countWords(value));
    }

    const toggle = () => setPlaying(!playing);

    return (
        <>
            <Header />
            <Container>
                <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={variantsGenerate}
                >
                    <CardWrapper>
                        <Gallery>
                            {selectedApe && (
                                <NFTCard
                                    image={selectedApe.nft_reference.image}
                                    cardNumber={`#${selectedApe.nft_reference.id}`}
                                    voiceID={selectedApe.voice_id}
                                />
                            )}
                        </Gallery>
                        <FormWrapper onSubmit={handleGenerate}>
                            <Title>Make it talk</Title>
                            <SelectContainer>
                                <DropdownWrapper className={dropdownOpen ? 'open' : ''}>
                                    <DropdownButton>
                                        <DropdownLogo src={serumImg} alt=""/>
                                        <PlayButton onClick={(e) => {
                                            e.preventDefault();
                                            toggle();
                                        }}>
                                            {playing ?
                                                <img src={iconPause} alt="Pause" style={{width: 9}} />
                                                :
                                                <img src={triangle} alt="Play"/>
                                            }

                                        </PlayButton>
                                        <NameVoice onClick={toggleDropdown}>Voice
                                            #{selectedSerum?.voice_id}</NameVoice>
                                        <ArrowDown src={arrowDown} onClick={toggleDropdown} className="arrow-down" />
                                    </DropdownButton>
                                    {
                                        dropdownOpen ?
                                            <DropdownContent>
                                                <img src={banner} alt=""/>
                                            </DropdownContent> : null
                                    }
                                </DropdownWrapper>
                                <LogoImage src={logo} className={dropdownOpen ? 'open' : ''}/>
                            </SelectContainer>
                            <Textarea
                                name="your-text"
                                id="your-text"
                                placeholder="Write your text"
                                value={text}
                                onChange={(e) => handleTextInput(e)}
                                required={true}
                            />
                            <SubmitWrapper>
                                <ButtonForm type={"submit"}>
                                    <Button
                                        type="v3"
                                    >
                                        {generatedApeIsLoading ?
                                            <p>Generating</p>
                                            :
                                            <p>Make it talk <span>{wordCount} $HEART</span></p>
                                        }

                                    </Button>
                                </ButtonForm>
                            </SubmitWrapper>
                            <WordPrice>1 $HEART / WORD</WordPrice>
                        </FormWrapper>
                    </CardWrapper>
                </motion.div>

                <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={variantsVoicesStats}
                >
                    <VoicesStats />
                </motion.div>
            </Container>
        </>
    );
};

export default Generate;
