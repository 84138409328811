import styled from "styled-components";
import {Link} from "react-router-dom";

import {ModalWrapperInner} from "../../../../styles/modal.style";
import {NFTCardStyled} from "../../../../components/nft-card/NFTCard.style";
import {
    AiSerumStatsStyled,
    AISerumTitle,
    BuyMoreBtn,
    HeartCount
} from "../../../../components/ai-serum-stats/AISerumStats.style";

import iconX from '../../../../resources/img/icon-x.png';
import btnMore from '../../../../resources/img/btn-more.png';

export const ModalGenerateStyled = styled.div`
  
  ${ModalWrapperInner} {
    top: 78px;
  }
  
  ${NFTCardStyled} {
    width: 193px;
    
    &:after {
      content: "";
      display: block;
      width: 98px;
      height: 84px;
      background-image: url(${iconX});
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -43px;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    ${NFTCardStyled} {
      width: 250px;
      &:after {
        left: 50%;
        transform: translateX(-50%);
        top: auto;
        bottom: -42px;
        z-index: 1;
      }
    }
  }
`;

export const SerumWrapper = styled.div`
  max-width: 1007px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 47px;
  min-height: 411px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: ${props => props.theme.colors.black};
  border-radius: 45px;
  border: 0.5px solid rgba(255, 255, 255, .36);
  box-sizing: border-box;+
  box-shadow: 0px 2px 31px rgba(0,0,0, 0.5);
`;

export const AISerumList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  ${AiSerumStatsStyled} {
    &:before {
      content: none;
    }
    
    &:not(:first-child) {
      ${AISerumTitle} {
        display: none;
      }
    }

    ${BuyMoreBtn},
    ${HeartCount} {
      display: none;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    display: block;
    width: 100%;
    margin-top: 30px;

    ${AiSerumStatsStyled} {
      display: table;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

export const ButtonMore = styled(Link)`
  position: relative;
  display: block;
  height: 90px;
  width: 90px;
  background-image: url(${btnMore});
  margin-left: -30px;
  z-index: 1;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    margin-left: auto;
    margin-right: auto;
  }
`;
